import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../requests/api';
import { useUser } from '../hooks/useUser';

const LanguageContext = createContext();

export function useLanguage() {
  return useContext(LanguageContext)
}

export const languageCodes = {
  english: 'en',
  hebrew: 'he'
}

const LanguageProvider = ({ children }) => {
  const { user } = useUser()
  const [languageCode, setLanguageCode] = useState(languageCodes.english);
  const [languageData, setLanguageData] = useState({});
  const [tempToken, setTempToken] = useState(null);
  const isRTL = languageCode === languageCodes.hebrew
  const sessionKey = 'languageData';

  useEffect(() => {
    if (user || tempToken)
      preFetchLanguageData();
  }, [languageCode, user]);

  function ToggleLanguage(newLangCode = null, token = null) {
    if (token) setTempToken(token)
    let newLanguage = newLangCode ?? (languageCode === languageCodes.english ? languageCodes.hebrew : languageCodes.english)
    setLanguageCode(newLanguage);
    // Update the URL query parameter
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('lang', newLanguage);
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  }

  const preFetchLanguageData = async (token = null) => {
    const storedLanguageData = sessionStorage.getItem(sessionKey);
    if (storedLanguageData) {
      const parsedData = JSON.parse(storedLanguageData);
      if (parsedData[languageCode]) {
        setLanguageData(parsedData[languageCode]);
        return;
      }
    }
    const responseLanguageData = await fetchLanguageData(token);
    if (responseLanguageData) {
      // Store languageData in sessionStorage
      const newLanguageDataToStore = { [languageCode]: responseLanguageData };
      const languageToStore = storedLanguageData ? { ...JSON.parse(storedLanguageData), ...newLanguageDataToStore } : newLanguageDataToStore;
      sessionStorage.setItem(sessionKey, JSON.stringify(languageToStore));
    }
  }

  const fetchLanguageData = async (token = null) => {
    try {
      const dataUrl = `/languages?code=${languageCode}`;
      const headers = {}
      if (token) headers['X-Token'] = token;
      else if (tempToken) headers['X-Token'] = tempToken;
      const response = await api.get(dataUrl, { headers })
      setLanguageData(response.data);
      return response.data;
    } catch (err) {
      console.error(err)
      return null
    }
  };

  const fetchLanguageWithToken = async (token) => {
    setTempToken(token)
    preFetchLanguageData(token)
  }

  function changeToHebrew() {
    if (languageCode !== languageCodes.hebrew)
      ToggleLanguage(languageCodes.hebrew);
  }

  const setToken = (token) => {
    setTempToken(token)
  }

  return (
    <LanguageContext.Provider value={{ language: languageCode, ToggleLanguage, languageData, isRTL, changeToHebrew, fetchLanguageWithToken, setToken }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
