import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';

const ApproveModal = ({ open, onClose, onConfirm, title, subtitle, buttonLabel }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography>{subtitle}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        onConfirm();
                        onClose();
                    }}
                    color="error"
                    variant='contained'
                >
                    {buttonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ApproveModal;
