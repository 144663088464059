import { requestGenerateExam } from '../requests/exam';
import requestTemplate from '../requests/template'
import { reportClick, buttonsIds } from '../requests/analytics';
import { SnackbarTypes } from './snackbarTypes';
import { requestExamStatus } from '../requests/exam';
import { intervalCallback } from './callbacks';
import ROLES from './roles';


const generateExam = async (
    text,
    field,
    questions,
    difficulty,
    examLanguage,
    focus,
    skills,
    exampleQuestion,
    isFile,
    updateExamsLeft,
    openSnackBar,
    setIsLoading,
    payload,
    navigate,
    isClosedContent,
    useIndicator,
    roles,
    addNewExamToHistory
) => {

    if (isFile && (!text || text.length === 0)) {
        setIsLoading(false);
        openSnackBar(SnackbarTypes.ERROR.field)
        return;
    }

    if (!text || text?.length === 0) {
        openSnackBar(SnackbarTypes.NO_TEXT.field)
        setIsLoading(false);
    }
    reportClick(buttonsIds.generateExam);
    //setIsLoading(true);
    try {
        const response = await requestGenerateExam(
            text,
            field,
            questions,
            difficulty,
            examLanguage,
            focus,
            skills,
            exampleQuestion,
            payload,
            isClosedContent,
            useIndicator
        );
        const taskId = response.data.task_id;
        if (!taskId) {
            console.error('No task id received');
            openSnackBar("ERROR");
            setIsLoading(false);
            return;
        }

        const maxIterations = 100;  // Set the maximum number of iterations
        let exam_result_id = "";
        let responseStatus = null;
        const delay = 10000; // Set the delay in milliseconds
        await intervalCallback(() => requestExamStatus(taskId), maxIterations, delay)
            .then((result) => {
                responseStatus = result.status;
                exam_result_id = result.data.exam_id;
                generationOver(exam_result_id, responseStatus, setIsLoading, openSnackBar, updateExamsLeft, navigate, roles, addNewExamToHistory);
            })
            .catch((error) => {
                console.error('Error generating exam:', error);
                setIsLoading(false);
                openSnackBar("ERROR");
            });

    } catch (error) {
        console.error('Error generating exam:', error);
        setIsLoading(false)
        if (error === 'Transcript too long') {
            console.error('Limit exceeded error detected.');
            openSnackBar("TRANSCRIPT");
        }
        else if (error === 'No Subtitles') {
            openSnackBar("NO_SUBTITLES");
        }
        else {
            openSnackBar("ERROR");
        }
    }
};

function generationOver(exam_result_id, responseStatus, setIsLoading, openSnackBar, updateExamsLeft, navigate, roles, addNewExamToHistory) {

    if (responseStatus > 400) {
        console.error('Error generating exam:', response);
        setIsLoading(false);
        openSnackBar("ERROR")
    } else {
        try {
            const exam_id = exam_result_id;

            openSnackBar("HISTORY")
            updateExamsLeft()
            openSnackBar("SUCCESS")
            addNewExamToHistory(exam_id)
            let url = roles.includes(ROLES.STUDENT.label) ? `/quiz?id=${exam_id}` : `/preview?id=${exam_id}`
            navigate(url);
            setIsLoading(false);
        }
        catch (error) {
            console.error('Error generating exam:', error);
            setIsLoading(false);
            openSnackBar("ERROR")
        }
    }
}

const generateTemplate = async (
    setIsLoading,
    setOpenDialog,
    setExamData,
    openSnackBar
) => {
    try {
        const response = await requestTemplate();
        await new Promise((resolve) => setTimeout(resolve, 2000)); // wait 2 seconds for UX

        if (response?.status > 400) {
            console.error('Error generating template:', response);
            openSnackBar(SnackbarTypes.ERROR.field)
        } else {
            const { data_url, filename, exam_json } = response.data;
            reportClick(buttonsIds.tryTemplate);
            openSnackBar(SnackbarTypes.SUCCESS.field)
            setExamData({ examJson: exam_json });
            setOpenDialog(true);
        }
    } catch (error) {
        console.error('Error generating template:', error);
        setExamData((prevExamData) => ({ ...prevExamData, _id: null }))
        openSnackBar(SnackbarTypes.ERROR.field)
    }

    setIsLoading(false);
};

export { generateExam, generateTemplate };
