import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

// Define styled components
const CustomAccordion = styled(Accordion)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'none', // Remove elevation or box shadow
    border: `1px solid ${theme.palette.divider}`, // Add border
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    '& .MuiAccordionSummary-content': {
        fontSize: theme.typography.body1.fontSize, // Match font size
    },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    fontSize: theme.typography.body2.fontSize, // Match font size
}));

const faqs = [
    {
        question: 'איך AI יוצר מבחנים?',
        answer: 'המערכת שלנו יוצרת מבחנים באמצעות AI באיכות גבוהה תוך שניות. ניתן להכניס כל טקסט או קובץ, כולל PDF ומצגות PowerPoint, וה- AI ייצור את תוכן המבחן עבורך.',
    },
    {
        question: 'האם ניתן להוריד מבחנים כקובץ PDF?',
        answer: 'כן, לאחר יצירת המבחן ניתן להורידו בקלות כקובץ PDF חינמי לשימוש לא מקוון או לשיתוף.',
    },
    {
        question: 'האם AI תומך בבדיקה אוטומטית?',
        answer: 'כן, המערכת תומכת בבדיקה אוטומטית למבחנים שנוצרו. יכולת זו עוזרת לחסוך זמן בהערכת תשובות התלמידים ומספקת משוב מהיר ואיכותי.',
    },
    {
        question: 'האם אפשר לייצא את המבחן ל-Google Forms?',
        answer: 'המערכת מאפשרת לייצא את המבחנים ישירות ל-Google Forms. זה נועד להקל על המורים והתלמידים להשתמש בפלטפורמה שהם מכירים.',
    },
    {
        question: 'למי מתאימה המערכת ליצירת מבחנים באמצעות AI?',
        answer: 'המערכת ליצירת מבחנים מיועדת למורים, מחנכים, סטודנטים וכל מי שצריך ליצור מבחנים בצורה יעילה. אם אתם בתחום החינוך, הכשרה בעסקים או תחומים אחרים, AI ליצירת מבחנים מקל על תהליך יצירת המבחנים.',
    },
    {
        question: 'למה כדאי לי להשתמש ב- AI ליצירת מבחנים?',
        answer: 'המערכת מספקת דרך מהירה ויעילה ליצור מבחנים באיכות גבוהה באמצעות AI. המערכת מציעה בדיקה אוטומטית הכוללת ציון ומשוב, אפשרויות הורדת PDF, שילוב חלק עם Google Forms, ומפשט את כל תהליך יצירת המבחנים והפצתם.',
    },
    {
        question: 'האם יש מגבלות על סוגי הקבצים שניתן להכניס?',
        answer: 'לא, אפשר להכניס סוגים שונים של קבצים, כולל טקסט, PDF ומצגות PowerPoint. גמישות זו מבטיחה שתוכלו ליצור מבחנים בקלות באמצעות התוכן שכבר ברשותכם.',
    },
];

const HebrewFAQ = () => {
    return (
        <Container id="faq" style={{ padding: '64px 24px', margin: 'auto', fontFamily: 'Arial, sans-serif' }}>
            <Typography variant="h3" align="center" sx={{ mb: 4 }}>
                יש לך שאלות?
            </Typography>
            {faqs.map((faq, index) => (
                <CustomAccordion key={index}>
                    <CustomAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`faq-panel-${index}`} id={`faq-header-${index}`}>
                        <Typography variant="h6">{faq.question}</Typography>
                    </CustomAccordionSummary>
                    <CustomAccordionDetails>
                        <Typography>{faq.answer}</Typography>
                    </CustomAccordionDetails>
                </CustomAccordion>
            ))}
        </Container>
    );
};

export default HebrewFAQ;
