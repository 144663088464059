import React, { useEffect, useMemo } from 'react';
import { bloomsQuestions, questionsTypesOptions, defaultParams, defaultQuestion } from '../../utils/examOptions';
import { DeleteOutlineRounded, Add as AddIcon } from '@mui/icons-material';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, Select, MenuItem, IconButton, Button, Typography } from '@mui/material';
import './QuestionsTable.css';
import { useDebounce } from '../../hooks/useDebounce';
import ROLES from '../../utils/roles';
import { useLanguage } from '../../contexts/languageContext';
import CustomSelect from '../CustomSelect';
import { isMobile } from '../../utils';

const defaultMaxQuestions = 10
const tableHeaderCellStyle = isRTL => ({ paddingY: 1, textAlign: isRTL ? 'right' : 'left' })
const tableHeaderEdgeCellStyle = isRTL => ({ ...tableHeaderCellStyle(isRTL), width: '120px' })
const tableCellStyle = { padding: 1 }

function QuestionsTable({ remixQuestions, onChange, userRoles, maxFieldQuestions = 10 }) {
    /** @type {[{type: string, bloomType: string}[], Function]} */
    const [questions, setQuestions] = React.useState(remixQuestions ?? defaultParams.questions);
    const debouncedOnChange = useDebounce(onChange, 2000);
    const { languageData, isRTL } = useLanguage();
    const maxQuestions = useMemo(() => getMaxQuestionsByRole(userRoles), [userRoles, maxFieldQuestions])

    useEffect(() => {
        if (remixQuestions)
            setQuestions(remixQuestions);
    }, [remixQuestions]);

    useEffect(() => {
        debouncedOnChange(questions);
    }, [questions, debouncedOnChange]);

    function getMaxQuestionsByRole(userRoles) {
        if (userRoles && (userRoles.includes(ROLES.PREMIUM.label) || userRoles.includes(ROLES.PRO.value)) && !maxFieldQuestions) {
            return 15
        } else if (maxFieldQuestions && maxFieldQuestions < defaultMaxQuestions) {
            return maxFieldQuestions
        } else {
            return defaultMaxQuestions
        }
    }
    const addQuestion = () => {
        if (questions.length >= maxQuestions) return;
        setQuestions(prevQuestions => [...prevQuestions, defaultQuestion]);
    };

    const updateQuestion = (questionToUpdateIndex, field, value) => {
        setQuestions(questions.map((question, index) =>
            index === questionToUpdateIndex ? { ...question, [field]: value } : question
        ));
    };

    const removeQuestion = (questionToRemoveIndex) => {
        setQuestions(prevQuestions => prevQuestions.filter((_, index) => index !== questionToRemoveIndex));
    };

    return (
        <Paper id="choices-manager" sx={{ padding: 2 }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="h6" style={{ marginBottom: 2, marginLeft: 3 }}>
                    {languageData?.questions_table?.total_questions + questions.length}
                </Typography>
                <Button variant="contained" onClick={addQuestion} sx={{ marginBottom: 2, borderRadius: '50%', minWidth: 0, height: isMobile ? '30px' : '40px', width: isMobile ? '30px' : '40px' }}>
                    <AddIcon />
                </Button>
            </div>
            <div style={{ overflow: 'scroll' }}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={tableHeaderEdgeCellStyle(isRTL)}></TableCell>
                            <TableCell sx={tableHeaderCellStyle(isRTL)}>{languageData?.questions_table?.question_type}</TableCell>
                            <TableCell sx={tableHeaderCellStyle(isRTL)}>{languageData?.questions_table?.question_level}</TableCell>
                            <TableCell sx={tableHeaderEdgeCellStyle(isRTL)}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {questions.map((question, questionsIndex) => (
                            <TableRow key={questionsIndex}>
                                <TableCell sx={{ ...tableCellStyle, textAlign: 'center' }}>
                                    <p>{languageData?.questions_table?.question + (questionsIndex + 1)}</p>
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <CustomSelect
                                        options={languageData?.questionsTypesOptions ?? questionsTypesOptions}
                                        value={question.type}
                                        onChangeHandler={(e) => updateQuestion(questionsIndex, 'type', e.target.value)}
                                        firstDisabledItem={languageData?.questions_table?.select_type}
                                        selectProps={{ inputProps: { sx: { padding: '8px 14px' } } }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <CustomSelect
                                        options={languageData?.bloomsQuestions ?? bloomsQuestions}
                                        value={question.bloomType}
                                        onChangeHandler={(e) => updateQuestion(questionsIndex, 'bloomType', e.target.value)}
                                        firstDisabledItem={languageData?.questions_table?.select_level}
                                        selectProps={{ inputProps: { sx: { padding: '8px 14px' } } }}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableCellStyle, textAlign: 'center' }}>
                                    <IconButton onClick={() => removeQuestion(questionsIndex)}>
                                        <DeleteOutlineRounded color="error" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </Paper >
    );
}

export default QuestionsTable;
