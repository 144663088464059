import React from "react";
import { questionsType } from '../../utils/examOptions'
import MultipleQuestion from './MultipleQuestion';
import OpenQuestion from './OpenQuestion';
import GraphQuestion from "./GraphQuestion";
import NestedQuestion from "./nestedQuestion";

function QuestionTypeManager({ type, allQuestionsProps, openQuestionProps, closeQuestionProps, graphQuestionProps, nestedQuestionsProps }) {
    return (
        <>
            {type === questionsType.open && (
                <OpenQuestion
                    {...allQuestionsProps}
                    {...openQuestionProps}
                />
            )}
            {(type === questionsType.multiple || type === questionsType.fill_in) && (
                <MultipleQuestion
                    {...allQuestionsProps}
                    {...closeQuestionProps}
                />
            )}
            {type === questionsType.graph && (
                <GraphQuestion
                    {...allQuestionsProps}
                    {...graphQuestionProps}
                />
            )}
            {type === questionsType.nested && (
                <NestedQuestion
                    {...allQuestionsProps}
                    {...nestedQuestionsProps}
                />
            )}
        </>
    )
}

export default QuestionTypeManager;