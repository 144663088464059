import Chip from '@mui/material/Chip';
import BetaIcon from '@mui/icons-material/Science';
import HotIcon from '@mui/icons-material/Whatshot';
import NewIcon from '@mui/icons-material/NewReleases';
import TrendingIcon from '@mui/icons-material/TrendingUp';
import StarIcon from '@mui/icons-material/Star';
import UpdateIcon from '@mui/icons-material/Update';

const CustomCardChip = ({ label, color, bgColor, icon, variant }) => {
    return (
        <Chip
            label={label ?? ''}
            icon={icon}
            variant={variant ?? 'filled'}
            size="small"
            sx={{
                color: color,
                backgroundColor: bgColor,
                fontWeight: 'bold',
                paddingY: 2,
                paddingX: 1,
                opacity: 0.8,
                '& .MuiChip-icon': {
                    color: color
                }
            }}
        />
    );
}

export const cardChips = {
    beta: {
        label: 'Beta',
        component: (
            <CustomCardChip
                label="Beta"
                color="#5C5C5C"
                bgColor="#EFEFEF"
                icon={<BetaIcon />}
            />
        )
    },
    hot: {
        label: 'Hot',
        component: (
            <CustomCardChip
                label="Hot"
                color="#D32F2F"
                bgColor="#FFCDD2"
                icon={<HotIcon />}
            />
        )
    },
    new: {
        label: 'New',
        component: (
            <CustomCardChip
                label="New"
                color="#388E3C"
                bgColor="#C8E6C9"
                icon={<NewIcon />}
            />
        )
    },
    trending: {
        label: 'Trending',
        component: (
            <CustomCardChip
                label="Trending"
                color="#F57C00"
                bgColor="#FFE0B2"
                icon={<TrendingIcon />}
            />
        )
    },
    featured: {
        label: 'Featured',
        component: (
            <CustomCardChip
                label="Featured"
                color="#1976D2"
                bgColor="#BBDEFB"
                icon={<StarIcon />}
            />
        )
    },
    updated: {
        label: 'Updated',
        component: (
            <CustomCardChip
                label="Updated"
                color="#7B1FA2"
                bgColor="#E1BEE7"
                icon={<UpdateIcon />}
            />
        )
    }
};
