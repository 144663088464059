import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, Container } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import useSnackbar from '../../hooks/useSnackBar';
import { requestVerifyEmail } from '../../requests/validation';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  text: {
    margin: '16px',
  },
};

// todo: open socket and move the user to the exam page if he purchased
const ValidationWaitingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { openSnackBar } = useSnackbar();

  useEffect(() => {
    handleValidateEmail(new URLSearchParams(location.search));
  }, [location.search]);

  async function handleValidateEmail(params) {
    const token = params.get('t'); // t stands for token
    const email = params.get('e'); // e stands for email
    try {
      const responseStatus = await requestVerifyEmail(token, email);
      if (responseStatus === 200) {
        setIsLoading(false);
        navigate('/start')
      } else {
        console.error('Email verification failed');
        openSnackBar('VALIDATION_FAILED');
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      openSnackBar('VALIDATION_FAILED');
    }
  }
  return (
    <div style={styles.container}>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '25px' }}>
          <CircularProgress size='5rem' />
          <Container>
            <Typography variant="h4" className={styles.text}>
              Validating your account...
            </Typography>
          </Container>
        </div>
      ) : (
        <Typography variant="h4" className={styles.text}>
          Validation successful. Redirecting...
        </Typography>
      )}
    </div>
  );
};

export default ValidationWaitingPage;
