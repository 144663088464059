import { Paper, Typography } from '@mui/material';
import React from 'react';
import { renderTextWithBreakLines } from '../utils';

function Unseen({ text }) {
    return text ? (
        <Paper style={{ padding: '20px 30px', marginBottom: '20px', borderRadius: '2px' }} >
            <Typography variant="h6">
                {renderTextWithBreakLines(text)}
            </Typography>
        </Paper>
    ) : null
}

export default Unseen;
