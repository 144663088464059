import React, { useEffect, useMemo } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardActions, Button, CircularProgress, LinearProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { colors, paddingPageStyle } from '../../utils/customTheme';
import Header from '../../components/Header';
import { isMobile } from '../../utils';
import { useLanguage } from '../../contexts/languageContext';
import { useLobby } from '../../contexts/LobbyContext';
import useHistory from '../../hooks/useHistory';
import { Add } from '@mui/icons-material';
import TimeSavedCard from './TimeSavedCard';
import { Helmet } from 'react-helmet-async';

const tempLoader = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </Box>
    );
}

const LobbyPage = () => {
    const navigate = useNavigate();
    const { history } = useHistory()
    const { user } = useUser();
    const { languageData, isRTL } = useLanguage();
    const { fetchLobbyStatus, lobbyStatus } = useLobby();

    if (!user) {
        return tempLoader();
    }

    useEffect(() => {
        // fetch the profile stages data from the server
        if (!lobbyStatus) {
            fetchLobbyStatus();
        }
    }, [lobbyStatus]);

    const cardDetails = useMemo(() => [
        {
            title: languageData?.lobby?.email_verification?.title,
            condition: true,
            action: null,
            description: languageData?.lobby?.email_verification?.description
        },
        {
            title: languageData?.lobby?.first_exam?.title,
            condition: !!lobbyStatus?.first_exam,
            action: '/exam',
            description: languageData?.lobby?.first_exam?.description
        },
        {
            title: languageData?.lobby?.auto_grade?.title,
            condition: !!lobbyStatus?.auto_grade,
            action: '/history',
            description: languageData?.lobby?.auto_grade?.description
        },
        {
            title: languageData?.lobby?.review?.title,
            condition: !!lobbyStatus?.review,
            action: '/history',
            description: languageData?.lobby?.review?.description
        },
        // Uncomment and add descriptions for additional features as needed
        // {
        //     title: 'Share Exam',
        //     condition: false,
        //     action: '/history',
        //     description: 'Share your exams with others or publish them for wider access.'
        // },
        // {
        //     title: 'Generate AI Report',
        //     condition: false,
        //     action: '/history',
        //     description: 'Generate detailed AI-powered reports on exam performance and trends.'
        // },
        // {
        //     title: 'Upload To Hub',
        //     condition: false,
        //     action: '/explore',
        //     description: 'Upload your exams to the central hub for easy access and sharing.'
        // },
        // {
        //     title: 'Upload Exam',
        //     condition: false,
        //     action: '/exam',
        //     description: 'Upload exams to the platform for grading or distribution.'
        // },
    ], [lobbyStatus, languageData]);


    const completedConditions = cardDetails.filter(card => card.condition).length;
    const profileCompletion = (completedConditions / cardDetails.length) * 100;

    const renderCard = ({ title, condition, action, description }) => {
        const bgColor = condition ? colors.success : 'white';
        const color = condition ? 'white' : colors.primary;
        return (
            <Grid item xs={12} sm={6} md={3} key={title}>
                <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', bgcolor: bgColor, color, direction: isRTL ? 'rtl' : 'ltr' }}>
                    <CardContent sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                        <div>
                            <Typography variant="h6" sx={{ color }} gutterBottom>{title}</Typography>
                            <Typography variant="body1" sx={{ color }} gutterBottom>{description}</Typography>
                        </div>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'space-between' }}>
                        {condition && <CheckCircleIcon sx={{ color }} />}
                        {action && <Button variant='outlined' size="small" onClick={() => navigate(action)} sx={{ color }}>{languageData?.lobby?.click_here}</Button>}
                    </CardActions>
                </Card>
            </Grid>
        );
    };

    if (!languageData || !lobbyStatus)
        return tempLoader();

    const examsCreated = history?.length;
    const examsEvaluated = history?.reduce((total, exam) => total + exam.responses_count, 0);

    const firstName = user?.fullname?.split(' ')[0];
    return (
        <>
            <Helmet>
                <title>Exam Generator | Lobby</title>
                <meta name="description" content="Welcome to the Lobby. Here, you can easily access your personalized exam generator dashboard, create new exams, and manage your account. Start generating high-quality exams with our AI-powered tools today!" />
            </Helmet>
            <Header />
            <Box sx={{ p: isMobile ? 3 : 6, bgcolor: colors.onboarding, overflow: 'auto', ...paddingPageStyle }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent sx={{ direction: isRTL ? 'rtl' : 'ltr' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Typography variant="h5" sx={{ mb: 2, fontWeight: 600, overflowWrap: 'break-word' }}>{languageData?.lobby?.hi + (firstName ?? '')},</Typography>
                                    <Button variant="contained" onClick={() => navigate('/exam')} sx={{ marginBottom: 2, borderRadius: '50%', minWidth: 0, height: isMobile ? '32px' : '40px', width: isMobile ? '32px' : '40px' }}>
                                        <Add />
                                    </Button>
                                </div>
                                <Typography variant="h5" sx={{ mb: 2 }}>{languageData?.lobby?.get_to_know}</Typography>
                                <LinearProgress variant="determinate" value={profileCompletion} sx={{ height: 10, borderRadius: 5, transform: `scaleX(${isRTL ? -1 : 1})` }} />
                                <Typography variant="h6" color="text.secondary" mt={1}>{profileCompletion + languageData?.lobby?.profile_completion}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} sx={{ flexDirection: isRTL ? 'row-reverse' : 'row' }}>
                            {cardDetails.map(renderCard)}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TimeSavedCard examsCreated={examsCreated} examsEvaluated={examsEvaluated} />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default LobbyPage;
