import { React, useMemo } from 'react';
import { Paper, Grid, Card, CardContent, Typography, IconButton } from '@mui/material';
import { colors } from '../../utils/customTheme';
import { useLanguage } from '../../contexts/languageContext';
import { getOnboardingRoles } from '../../utils/roles';

const RoleSelectionStep = ({ onSelect, selectedRoleId }) => {
  const { languageData } = useLanguage();
  const rolesConfig = useMemo(() => languageData ? getOnboardingRoles(languageData) : [], [languageData]);
  const handleRoleClick = (roleId) => {
    onSelect(roleId);
  };
  return (
    <Grid container spacing={2} sx={{ backgroundColor: colors.onboarding, justifyContent: 'center' }}>
      <Grid item xs={12}>
        <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>{languageData?.onboarding?.role_step?.title}</Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary">{languageData?.onboarding?.role_step?.subtitle}</Typography>
      </Grid>
      {rolesConfig.map((roleData) => (
        <Grid item xs={12} sm={4} key={roleData._id} sx={{ display: 'flex' }}>
          <Paper elevation={0} sx={{ border: selectedRoleId === roleData._id ? `2px solid ${colors.main}` : '2px solid transparent', transition: 'border-color 0.3s', cursor: 'pointer' }}>
            <Card
              variant="outlined"
              onClick={() => handleRoleClick(roleData._id)}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <CardContent sx={{ flex: '1 1 auto', textAlign: 'center' }}>
                <IconButton aria-label={roleData.title} sx={{ padding: 0 }}>
                  {roleData.icon}
                </IconButton>
                <Typography variant="h6" component="div" sx={{ m: 2, fontWeight: 'bold' }}>
                  {roleData.title}
                </Typography>
                <Typography color="textSecondary">
                  {roleData.subtitle}
                </Typography>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default RoleSelectionStep;
