import { HttpStatusCode } from "axios"

/**
 * TODO: refactor all api requests to use this response processing 
 * @param {Promise} reqPromise 
 * @returns 
 */
export async function processResponsePromise(reqPromise) {
    return reqPromise.then(response => {
        if (response.status === HttpStatusCode.Ok)
            return response.data
        else {
            console.error(response.reason)
            throw Error(response.reason)
        }
    }).catch(e => {
        console.error(e)
        throw Error(e)
    })
}