import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Grid, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import SubjectList from './SubjectList';
import ExamHubList from './ExamHubList';
import Header from '../../components/Header';
import PreviewDialog from './PreviewDialog';
import subjects from '../../utils/subjectsData';
import { useHub } from '../../hooks/useHub';
import { isMobile } from '../../utils';
import { useLanguage } from '../../contexts/languageContext';
import { levelOptionsList, languageOptions } from '../../utils/examOptions';

const Hub = () => {
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedExamId, setSelectedExamId] = useState(null);
    const [selectedDifficulty, setSelectedDifficulty] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState("");

    const { hub, fetchHub } = useHub();
    const { isRTL, languageData } = useLanguage();

    useEffect(() => {
        fetchHub();
    }, []);

    const handleSubjectSelect = (subject) => {
        setSelectedSubject(subject);
    };

    const handleOpenPreviewDialog = (examId) => {
        setOpenDialog(true);
        setSelectedExamId(examId);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleUpvoteClick = (examId) => {
        const index = filteredExams.findIndex((exam) => exam.exam_id === examId);
        if (index !== -1) {
            const updatedExam = { ...filteredExams[index] };
            updatedExam.upvotes_count += 1;
            filteredExams[index] = updatedExam;
        }
    };

    const filteredExams = useMemo(() => {
        const filtered = hub?.filter((exam) => {
            const isSubjectMatch = !selectedSubject || exam.subject === selectedSubject.subject;
            const isDifficultyMatch = !selectedDifficulty || exam.difficulty === selectedDifficulty;
            const isLanguageMatch = !selectedLanguage || exam.language === selectedLanguage;

            return isSubjectMatch && isDifficultyMatch && isLanguageMatch;
        });
        return filtered;
    }, [hub, selectedSubject, selectedDifficulty, selectedLanguage]);


    const filteredSubjects = useMemo(() => {
        const subjectsWithExams = [...new Set(filteredExams?.map((exam) => exam.subject))];
        return subjects
            .filter((subject) => subjectsWithExams.includes(subject.subject))
            .map((subject) => ({
                ...subject,
            }));
    }, [filteredExams, isRTL]);


    return (
        <>
            <Header />
            <div style={{ padding: '10px' }}>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} md={10}>
                        <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="difficulty-select-label">{languageData?.difficulty_label}</InputLabel>
                                    <Select
                                        labelId="difficulty-select-label"
                                        value={selectedDifficulty}
                                        onChange={(e) => setSelectedDifficulty(e.target.value)}
                                        label={languageData?.difficulty_label}
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {levelOptionsList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="language-select-label">{languageData?.language_label}</InputLabel>
                                    <Select
                                        labelId="language-select-label"
                                        value={selectedLanguage}
                                        onChange={(e) => setSelectedLanguage(e.target.value)}
                                        label={languageData?.language_label}
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {languageOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {!selectedSubject && (
                            <>
                                <Alert
                                    severity="info"
                                    sx={{
                                        width: isMobile ? 'auto' : 'max-content',
                                        marginY: '10px',
                                        marginX: 'auto',
                                        direction: isRTL ? 'rtl' : 'ltr',
                                        textAlign: 'center'
                                    }}
                                >
                                    {languageData?.hub?.title}
                                </Alert>
                                <SubjectList subjects={filteredSubjects} exams={filteredExams} onSubjectSelect={handleSubjectSelect} />
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12} md={10}>
                        {selectedSubject && (
                            <ExamHubList
                                examHubItems={filteredExams}
                                handleUpvote={handleUpvoteClick}
                                onBack={() => handleSubjectSelect(null)}
                                onOpen={handleOpenPreviewDialog}
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
            {openDialog && selectedExamId && <PreviewDialog open={openDialog} handleClose={handleCloseDialog} examId={selectedExamId} />}
        </>
    );
};

export default Hub;
