// ManagerDashboardContext.js
import React, { createContext, useContext, useState } from 'react';
import ROLES from '../utils/roles';
import { getOrg, getOrgExamsTakenData, getOrgResponsesCount, getOrgTokensCount, getOrgUsers } from '../requests/organizations';
import { useUser } from '../hooks/useUser';

const ManagerDashboardContext = createContext();

export const ManagerDashboardProvider = ({ children }) => {
    const [managerDashboardData, setManagerDashboardData] = useState({});
    const { user } = useUser()

    if (!user || !user?.org_id || !user?.roles?.some(role => role === ROLES.MANAGER.label))
        // Do not use or activate 
        return <>{children}</>

    async function fetchOrg(orgId) {
        return getOrg(orgId).then(org => {
            if (org)
                setManagerDashboardData(prevData => ({ ...prevData, org: org }))
        })
    }

    async function fetchOrgUsers(orgId) {
        return getOrgUsers(orgId).then(users => {
            if (users && Array.isArray(users)) {
                setManagerDashboardData(prevData => ({ ...prevData, users: users }))
            }
        })
    }

    async function fetchExamTaken(orgId) {
        return getOrgExamsTakenData(orgId).then(examsTakenData => {
            if (examsTakenData) {
                setManagerDashboardData(prevData => ({ ...prevData, examsTaken: examsTakenData }))
            }
        })
    }

    async function fetchResponsesCount(orgId) {
        return getOrgResponsesCount(orgId).then(responsesCountData => {
            if (responsesCountData) {
                setManagerDashboardData(prevData => ({ ...prevData, responsesCount: responsesCountData }))
            }
        })
    }

    async function fetchTokensCount(orgId) {
        return getOrgTokensCount(orgId).then(tokensCountData => {
            if (tokensCountData) {
                setManagerDashboardData(prevData => ({ ...prevData, tokensCount: tokensCountData }))
            }
        })
    }
    return (
        <ManagerDashboardContext.Provider value={{ managerDashboardData, fetchOrgUsers, fetchExamTaken, fetchResponsesCount, fetchTokensCount, fetchOrg }}>
            {children}
        </ManagerDashboardContext.Provider>
    );
};

export const useManagerDashboard = () => useContext(ManagerDashboardContext);
