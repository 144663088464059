import React, { useRef } from 'react';
import { Grid, Card, CardContent, Typography, Box, Avatar, TextField, Paper } from '@mui/material';
import { colors } from '../../utils/customTheme';
import { useLanguage } from '../../contexts/languageContext';

const SubjectSelectionStep = ({ subjectsConfig, handleSubjectSelection, selectedSubjects }) => {
  const otherSubjectRef = useRef('');
  const { languageData } = useLanguage();

  const handleSubjectChange = (event) => {
    const subject = event.target.value;
    if (subject && subject !== '') {
      const updatedSubjects = selectedSubjects.includes(subject)
        ? selectedSubjects.filter((prevSubject) => prevSubject !== subject)
        : [...selectedSubjects, subject];

      handleSubjectSelection(updatedSubjects);
    }
  };

  const handleOtherSubjectChange = (event) => {
    otherSubjectRef.current = event.target.value;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>{languageData?.onboarding?.subject_step?.title || 'Please choose your subjects'}</Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary">{languageData?.onboarding?.subject_step?.subtitle || 'Generation will be related to subjects you choose here'}</Typography>
      </Grid>
      {subjectsConfig.map((subject, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Paper elevation={0} sx={{ border: selectedSubjects.includes(subject.value) ? `2px solid ${colors.main}` : '2px solid transparent', transition: 'border-color 0.3s', cursor: 'pointer' }}>
            <Card
              variant="outlined"
              onClick={() => handleSubjectChange({ target: { value: subject.value } })}
            >
              <CardContent>
                <Box display="flex" alignItems="center">
                  <Avatar sx={{ bgcolor: colors.main }}>
                    {subject.icon}
                  </Avatar>
                  <Typography variant="body1" sx={{ ml: 2 }}>{subject.name}</Typography>
                </Box>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      ))}
      <Grid item xs={12}>
        <TextField
          label= {languageData?.onboarding?.subject_step?.other || 'Other Subject'}
          placeholder={languageData?.onboarding?.subject_step?.type_other || 'Type other subject here'}
          onChange={handleOtherSubjectChange}
          onBlur={(event) => handleSubjectChange({ target: { value: event.target.value } })}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default SubjectSelectionStep;
