import { Button, CircularProgress, Container, Divider, FormControl, FormHelperText, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Radio, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridFilterInputValue, GridToolbar, getGridStringOperators } from '@mui/x-data-grid';
import { useDashboard } from '../../contexts/Dashboard/DashboardContext';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { CreateEmailTemplateDialog } from '../../components/Dashboard/CreateEmailTemplateDialog';
import { useEmailsDashboard } from '../../contexts/Dashboard/EmailsContext';
import '../../utils/typedefs/emails'
import '../../utils/typedefs/dashboard'
import { sendEmail } from '../../requests/emails';
import { isEqual } from 'lodash';
import { DeleteConfirmationPopver } from '../../components/DeleteConfirmationPopover';

const doesNotContainFilter = (filterItem, column) => {
    if (!filterItem.field || !filterItem.value) {
        return null;
    }
    return (value, row, column, apiRef) => {
        return value.toLowerCase().indexOf(filterItem.value.toLowerCase()) === -1;
    };
};

const dateFormatter = params => params ? new Date(params).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' }) : null

const columns = [
    {
        field: 'email', headerName: 'Email', width: 200, type: 'string', filterOperators: [
            ...getGridStringOperators(),
            { label: 'Not contains', value: 'not contains', getApplyFilterFn: doesNotContainFilter, InputComponent: GridFilterInputValue }
        ]
    },
    { field: 'fullname', headerName: 'Full name', width: 120, type: 'string' },
    { field: 'roles', headerName: 'Roles', width: 110, type: 'string' },
    { field: 'exams_left', headerName: 'Exams Left', width: 90, type: 'number' },
    { field: '_id', headerName: 'ID', type: 'string' },
    { field: 'created_date', headerName: 'Created Date', width: 170, type: 'date', valueFormatter: dateFormatter },
    { field: 'org_id', headerName: 'Org ID', type: 'string' },
    { field: 'login_timestamps', headerName: 'Total Logins', type: 'number' },
    { field: 'last_email_type', headerName: 'Last Email Sent', width: 150, type: 'string' },
    {
        field: 'last_email_date', headerName: 'Last Email Date', width: 170, type: 'date', valueFormatter: dateFormatter
    },
];
const maxChunkSize = 13
export default function EmailsDashboard() {
    const { dashboardData, fetchDashboardData } = useDashboard()
    /**@type {{users: Users}} */
    const { users } = dashboardData || {}
    const { emailsDashboardData, fetchEmailsDashboardData, saveTemplate, deleteTemplate } = useEmailsDashboard()
    /** @type {{emails: Email[], templates: EmailTemplate[]}} */
    const { emails, templates } = emailsDashboardData || {}

    const [displayUsers, setDisplayUsers] = useState(null)
    const [selectedUsersIds, setSelectedUsersIds] = useState(null)
    const [selectedTemplateId, setSelectedTemplateId] = useState(null)
    const [openTemplateDialog, setOpenTemplateDialog] = useState(false)
    const [deletePopoverAnchorEl, setDeletePopoverAnchorEl] = useState(null);
    const [sendLoading, setSendLoading] = useState(false)
    const selectedTemplate = useMemo(() => templates?.find(t => t._id === selectedTemplateId), [selectedTemplateId])


    useEffect(() => {
        if (!dashboardData)
            fetchDashboardData()
        if (!emailsDashboardData)
            fetchEmailsDashboardData()
    }, [])

    useEffect(() => {
        if (users) {
            const newDisplayUsers = addEmailDataToUsers(emails, users, templates)
            // Only update the state if there is an actual change
            setDisplayUsers(prevDisplayUsers => {
                if (!isEqual(prevDisplayUsers, newDisplayUsers)) {
                    return newDisplayUsers;
                }
                return prevDisplayUsers;
            });
        }
    }, [users, emails, templates])

    const handleToggle = (id) => () => {
        if (selectedTemplateId === id)
            setSelectedTemplateId(null)
        else {
            setSelectedTemplateId(id)
        }
    };


    function handleSendEmail(e) {
        if (!selectedUsersIds || selectedUsersIds?.length == 0) {
            console.error('no users selceted')
            return;
        }
        if (!selectedTemplateId) {
            console.error('no email template selceted')
            return;
        }
        setSendLoading(true)
        const users_emails = selectedUsersIds.map(id => users.find(user => user._id === id)?.email)
        sendEmail(users_emails, selectedTemplateId).then(() => {
            setSendLoading(false)
        })
    }

    const handleCloseDialog = () => {
        setOpenTemplateDialog(false)
        setSelectedTemplateId(null)
    }

    const handleClickNewTemplate = () => {
        setSelectedTemplateId(null)
        setOpenTemplateDialog(true)
    }

    const submitTemplate = (template) => {
        saveTemplate(template).then(res => {
            console.log('Template saved!')
        }).catch(e => console.log('Saving template failed!'))
        setOpenTemplateDialog(false)
    }

    const handleDeleteTemplate = () => {
        deleteTemplate(selectedTemplateId).then(() => {
            setDeletePopoverAnchorEl(null)
            setSelectedTemplateId(null)
        }).catch(e => console.log('Deleting template failed!'))
    }

    return dashboardData && (
        <Container sx={{ width: '95%', m: 'auto', mt: 3 }}>
            <Typography variant='h4' sx={{ textAlign: 'center', marginBottom: 3 }}>
                Emails Dashboard
            </Typography>
            <div style={{ height: 550, width: '100%' }}>
                <DataGrid
                    getRowId={(item) => item._id}
                    rows={displayUsers || []}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 100 },
                        },
                    }}
                    checkboxSelection
                    slots={{
                        toolbar: GridToolbar
                    }}
                    loading={!users}
                    onRowSelectionModelChange={(ids) => setSelectedUsersIds(ids)}
                />
            </div>

            {/* Emails templates */}
            <div style={{ height: 'auto', width: '100%', margin: '3rem auto 5rem auto', display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h5' textAlign={'center'}>Choose email to send:</Typography>
                <Paper sx={{ width: '70%', margin: '1rem auto' }}>
                    <List>
                        {templates && templates?.map((template) => {
                            const labelId = `checkbox-list-label-${template?.title}`;
                            return (
                                <Fragment key={template?._id}>
                                    <ListItem key={template?._id} disablePadding >
                                        <ListItemButton role={undefined} onClick={handleToggle(template?._id)} dense>
                                            <ListItemIcon>
                                                <Radio
                                                    edge="start"
                                                    checked={selectedTemplateId === template?._id}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={template?.title} secondary={template?.type} />
                                            <IconButton edge="end" aria-label="edit" onClick={() => setOpenTemplateDialog(true)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton edge="end" aria-label="edit" sx={{ marginLeft: 3 }} onClick={e => setDeletePopoverAnchorEl(e.currentTarget)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </Fragment>
                            );
                        })}
                        <ListItem key='addTemplate' disablePadding>
                            <ListItemButton onClick={handleClickNewTemplate} dense>
                                <ListItemText id={'addTemplate'} primary={`New Template`} secondary={'Click here to add a new template'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Paper>
                <FormControl sx={{ m: 'auto', mt: 2, width: '200px' }}>
                    <Button variant="contained" disabled={sendLoading} onClick={handleSendEmail}>
                        Send Email &nbsp;&nbsp;&nbsp; {sendLoading && <CircularProgress color="grey" size={24} />}
                    </Button>
                    {sendLoading && selectedUsersIds && <FormHelperText>Sending should take around {Math.ceil(selectedUsersIds?.length / maxChunkSize)} seconds </FormHelperText>}
                </FormControl>
            </div >

            <CreateEmailTemplateDialog
                template={selectedTemplate}
                handleClose={handleCloseDialog}
                open={openTemplateDialog}
                onSubmit={submitTemplate}
            />
            <DeleteConfirmationPopver
                anchorEl={deletePopoverAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={() => setDeletePopoverAnchorEl(null)}
                onCancel={() => setDeletePopoverAnchorEl(null)}
                onDelete={handleDeleteTemplate}
                slotProps={{ paper: { sx: { p: 1 } } }}
            />
        </Container >
    );
}

/**
 * 
 * @param {Email[]} emails 
 * @param {Users} users 
 * @param {EmailTemplate[]} templates 
 * @returns The users array with last_email_date(most recent email's timestamp sent) & last_email_type(title of the email) fields
 */
function addEmailDataToUsers(emails, users, templates) {
    // Create a dictionary to store the most recent email timestamp for each user
    const recentEmails = {};
    if (emails) {
        // Iterate through the emails array
        emails.forEach(email => {
            const { user_email, timestamp, subject, template_id } = email;

            // If the user_email is already in the dictionary, compare timestamps
            if (!recentEmails[user_email] || new Date(timestamp) > new Date(recentEmails[user_email].timestamp)) {
                // If subject exists, use it as last_email_type
                recentEmails[user_email] = { timestamp }
                if (subject) {
                    recentEmails[user_email] = { ...recentEmails[user_email], last_email_type: subject };
                } else if (template_id && templates) {
                    // Find the template and use its title as last_email_type
                    const template = templates.find(template => template._id === template_id);
                    if (template) {
                        recentEmails[user_email] = { ...recentEmails[user_email], last_email_type: template?.title };
                    }
                }
            }
        });

    }
    // Update users array with last_email_date
    const newDisplayUsers = users.map(user => {
        const { email } = user;
        const emailDetails = recentEmails[email];
        return {
            ...user,
            last_email_date: emailDetails ? new Date(emailDetails.timestamp) : null,
            last_email_type: emailDetails ? emailDetails.last_email_type : null
        };
    });

    return newDisplayUsers
}