import { Button, Popover, Typography } from "@mui/material";
import { useLanguage } from "../contexts/languageContext";

export function DeleteConfirmationPopver({ anchorEl, open = Boolean(anchorEl), onClose, anchorOrigin, slotProps = { paper: { sx: { p: 1, pt: 0 } } }, onCancel, onDelete }) {
    const { languageData } = useLanguage();
    return (
        <Popover
            id={'delete-popover'}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={anchorOrigin}
            slotProps={slotProps}
        >
            <Typography sx={{ p: 2 }}>{languageData?.delete_confirmation?.title ?? 'Confirm to Delete'}</Typography>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                <Button variant='outlined' onClick={onCancel}>{languageData?.delete_confirmation?.cancel ?? 'Cancel'}</Button>
                <Button variant='contained' color="error" onClick={onDelete}>{languageData?.delete_confirmation?.delete ?? 'Delete'}</Button>
            </div>
        </Popover>
    )
}