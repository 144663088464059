import React from 'react';
import { Container, Grid, Box, Typography, Button, Stack } from '@mui/material';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SchoolIcon from '@mui/icons-material/School';
import BusinessIcon from '@mui/icons-material/Business';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const HebrewFeaturesSmall = () => {
    const iconSize = '2rem';
    const navigate = useNavigate();

    const content = [
        {
            icon: <SchoolIcon sx={{ color: 'primary.main', fontSize: iconSize }} />,
            title: "מורים",
            subtitle: "שפרו את תהליכי ההוראה עם בחנים, הערכות ומבחנים מבוססי AI. חסכו זמן ביצירת תוכן ובדיקה, והגבירו את מעורבות התלמידים"
        },
        {
            icon: <AutoStoriesIcon sx={{ color: 'primary.main', fontSize: iconSize }} />,
            title: "תלמידים",
            subtitle: "תלמדו חכם יותר באמצעות בחנים מבוססי בינה מלאכותית וטפסי Google מותאמים אישית. חסכו זמן, שפרו את הזיכרון, והצטיינו בבחינות ללא מאמץ."
        },
        {
            icon: <BusinessIcon sx={{ color: 'primary.main', fontSize: iconSize }} />,
            title: "עסקים",
            subtitle: "ייעלו את תהליכי ההערכה שלכם כדי לקדם ידע ולהעריך מיומנויות ביעילות. בין אם מדובר בהעברת הדרכות, פיתוח קורסים, או הכשרות ייעודיות."
        }
    ];

    const moveToSignup = () => {
        navigate('/signup');
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: 60 }}>
            <Container>
                <Box textAlign="center" mb={6} >
                    <Typography variant="h4" gutterBottom>
                        למי זה מתאים?
                    </Typography>
                </Box>
                <Grid container spacing={6}>
                    {content.map((item, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <Stack direction={'row'} alignItems="center" spacing={1} >
                                {item.icon}
                                <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                                    {item.title}
                                </Typography>
                            </Stack>
                            <Typography variant="body1" style={{ marginBottom: '1.5rem', marginTop: '1.0rem', color: '#757575' }}>
                                {item.subtitle}
                            </Typography>
                            <Button
                                onClick={moveToSignup}
                                variant="outlined"
                                color="primary"
                                endIcon={<ArrowForwardIcon />}
                            >
                                התחל עכשיו
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
};

export default HebrewFeaturesSmall;
