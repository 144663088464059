import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { AssignmentTurnedIn, Assessment, Chat } from '@mui/icons-material';
import AutoGradingImg from '../../../assets/features/auto-grade.png'; // Placeholder image
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const AutoGrading = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4}>
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <AssignmentTurnedIn color='primary' sx={{ fontSize: 40, mr: 1, mt: 0, left: 0, margin: 1 }} />}
                                <Typography
                                    variant="h3"
                                    sx={{ flexGrow: 1, fontSize: '35px' }}
                                    component="h1"
                                    aria-label="AI-Powered Auto Grading for Assessments and Tests"
                                >
                                    Transform Your Grading Process with AI-Powered Auto Grading for Assessments and Tests
                                </Typography>
                            </Box>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mb: 4, lineHeight: 1.8 }}
                            >
                                Revolutionize your grading process with our state-of-the-art AI-powered auto grading tool. In today’s fast-paced educational environment, educators face immense pressure to manage their time efficiently while maintaining high standards of assessment and feedback. Our tool is designed to alleviate the burden of manual grading, freeing up valuable time for educators to focus on what matters most—engaging with students and driving educational excellence. With our AI-powered auto grading solution, you can effortlessly automate the grading of a wide range of assessments, from multiple-choice quizzes to essay-based exams, ensuring that your students receive timely, accurate, and consistent feedback.
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mb: 4, lineHeight: 1.8 }}
                            >
                                Our AI technology is built on advanced algorithms that have been meticulously trained to understand and evaluate a variety of answer formats. Whether you are grading short answers, essays, or even complex problem-solving questions, our AI system can handle it all with precision. The tool not only marks correct answers but also provides detailed feedback on incorrect responses, guiding students towards the correct answers and helping them understand their mistakes. By leveraging the power of AI, educators can ensure that their grading is not only faster but also more objective, eliminating the potential for human bias and errors that can sometimes occur in manual grading.
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mb: 4, lineHeight: 1.8 }}
                            >
                                Moreover, our AI-powered auto grading tool is designed to seamlessly integrate with your existing learning management systems (LMS) and assessment platforms. Whether you are using Google Classroom, Moodle, or any other popular LMS, our tool can be easily incorporated into your workflow, allowing you to grade assessments with just a few clicks. The intuitive interface ensures that educators of all tech skill levels can use the tool effectively, without the need for extensive training or technical support. As a result, you can start reaping the benefits of automated grading almost immediately, with minimal disruption to your current processes.
                            </Typography>
                        </Grid>

                        {/* Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography
                                    variant="h3"
                                    component="h2"
                                    sx={{ mb: 2, fontSize: '35px' }}
                                >
                                    Key Benefits of AI-Powered Auto Grading
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{ mt: 2, lineHeight: 1.8 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <AssignmentTurnedIn color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6" >
                                            Enhanced Efficiency and Time Savings
                                        </Typography>
                                    </Box>
                                    Automate the grading process of tests, quizzes, and assignments to significantly boost your efficiency and reduce the manual effort required. Our AI system processes each assessment with lightning speed, allowing educators to dedicate more time to designing curriculum, interacting with students, and enhancing their educational offerings. The time saved through automation means teachers can focus more on personalized instruction and less on repetitive grading tasks. Imagine the relief of no longer needing to spend countless hours poring over student submissions, trying to ensure that each one is graded fairly and accurately. With our AI tool, you can rest assured that your grading is done quickly and correctly, giving you more time to focus on what you do best—teaching.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{ mt: 2, lineHeight: 1.8 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Assessment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6" >
                                            Improved Accuracy and Consistency
                                        </Typography>
                                    </Box>
                                    Leverage the power of AI to achieve unprecedented levels of accuracy and consistency in grading. Our advanced algorithms are designed to minimize the discrepancies that often arise from human error. By providing objective evaluations based on predetermined criteria, our AI ensures that every student's work is judged fairly and uniformly. This not only builds trust in the grading process but also enhances the credibility of the assessments themselves. Consistency is key when it comes to grading, and our AI-powered tool ensures that every student is assessed according to the same standards, regardless of when or by whom their work is graded. This level of consistency is particularly important in large classes or when multiple educators are involved in grading, as it eliminates the variability that can sometimes occur in manual grading.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{ mt: 2, lineHeight: 1.8 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Chat color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6" >
                                            Instant and Constructive Feedback
                                        </Typography>
                                    </Box>
                                    Provide your students with immediate feedback on their assignments and tests, helping them quickly understand their strengths and areas for improvement. Instant feedback is a powerful tool for student engagement, fostering a proactive approach to learning. Our AI-powered system delivers detailed, constructive feedback tailored to each student’s performance, encouraging them to focus on their growth areas and achieve better outcomes in future assessments. The feedback provided by our AI tool is not just about marking right or wrong answers—it’s about offering insights that help students learn and improve. For example, if a student consistently struggles with a particular concept, the AI can identify this and suggest additional resources or practice questions to help them master the material. This personalized approach to feedback is invaluable in supporting student learning and helping them achieve their full potential.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{ mt: 2, lineHeight: 1.8 }}
                                >
                                    In addition to the immediate benefits of automated grading, our AI-powered tool also provides long-term advantages for educators and institutions. By analyzing the data collected through grading, educators can gain valuable insights into student performance, identify trends, and make data-driven decisions to improve their teaching strategies. For instance, if the AI identifies that a large number of students are struggling with a particular topic, the educator can adjust their teaching approach to address this issue. Similarly, the data can be used to track student progress over time, helping educators to identify students who may need additional support or intervention. This level of insight is invaluable in creating a more personalized and effective learning experience for students.
                                </Typography>
                            </Box>

                            <Typography
                                variant="h3"
                                component="h2"
                                sx={{ mb: 2, fontSize: '35px' }}
                            >
                                How to Effectively Implement AI for Auto Grading in Your Assessment Workflow
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mt: 2, mb: 4, lineHeight: 1.8 }}
                            >
                                Integrating our AI-powered auto grading tool into your existing assessment workflow is seamless and straightforward. Our platform is designed to complement your current processes, providing a layer of automation that enhances the overall educational experience. From initial setup to daily use, our system is intuitive, ensuring that even educators with minimal tech experience can implement AI grading without any hassle. By adopting our tool, you'll gain access to detailed insights and analytics that can inform your teaching strategies, drive student success, and uphold the highest standards of educational excellence.
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mb: 4, lineHeight: 1.8 }}
                            >
                                Our AI-powered auto grading tool is also highly customizable, allowing you to tailor it to your specific needs and preferences. Whether you want to adjust the grading criteria, set different weights for various types of questions, or provide custom feedback, our tool offers the flexibility you need to create a grading system that works for you. Additionally, the tool is designed to grow with you, continually learning and adapting to your grading style over time. This means that the more you use it, the more accurate and personalized it becomes, further enhancing the efficiency and effectiveness of your grading process.
                            </Typography>

                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    href="#"
                                    aria-label="Learn more about AI-powered auto grading"
                                >
                                    Learn More
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default AutoGrading;
