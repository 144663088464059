import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import SelectionTags from './SelectionTags'
import { Typography, Grid } from '@mui/material'
import { useLanguage } from '../../contexts/languageContext'
import { useSkills } from '../../contexts/SkillsContext'

const getLang = (language) => language.focus_style_dialog || {
    focus_text: "What should this exam focus on?",
    example_text: "Write a sample question",
    exam_style_text: "Choose exam skills",
    seeMore: "see more",
    submit_btn: "submit",
    focus_placeholder: "I want you to focus on ______",
    example_placeholder: "Example question here",
    helper_texts: {
        focus: "Enter the focus of the exam (max 500 characters)",
        question: "Provide an example question for clarification (max 500 characters)"
    }
}

const PersonalizationInputs = ({ handleSubmit, personalizedData }) => {
    const [examFocus, setExamFocus] = useState(personalizedData.focus || '')
    const [selectedSkills, setSelectedSkills] = useState(personalizedData.skills || [])
    const [exampleQuestion, setExampleQuestion] = useState(personalizedData.exampleQuestion || '')
    const { skills } = useSkills();
    const { isRTL, languageData } = useLanguage();
    const lang = getLang(languageData);

    useEffect(() => {
        if (personalizedData) {
            const { focus, skills, exampleQuestion } = personalizedData;
            if (focus) setExamFocus(focus);
            if (skills) setSelectedSkills(skills);
            if (exampleQuestion) setExampleQuestion(exampleQuestion);
        }
    }, [personalizedData]);

    const onChangeExamFocus = (e) => {
        const value = e.target.value;
        if (value.length <= 500) {
            setExamFocus(value);
            handleSubmit(selectedSkills, value, exampleQuestion);
        }
    };

    const onChangeExampleQuestion = (e) => {
        const value = e.target.value;
        if (value.length <= 500) {
            setExampleQuestion(value);
            handleSubmit(selectedSkills, examFocus, value);
        }
    };

    const onSelectTags = (tags) => {
        setSelectedSkills(tags);
        handleSubmit(tags, examFocus, exampleQuestion);
    };

    return (
        <Grid container spacing={2} p={3} bgcolor="white">
            <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                    {lang.focus_text}
                </Typography>
                <TextField
                    minRows={2}
                    maxRows={5}
                    placeholder={lang.focus_placeholder}
                    variant='outlined'
                    fullWidth
                    multiline
                    value={examFocus}
                    onChange={onChangeExamFocus}
                    inputProps={{ maxLength: 500 }}
                    helperText={lang.helper_texts.focus}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                    {lang.example_text}
                </Typography>
                <TextField
                    minRows={2}
                    maxRows={5}
                    placeholder={lang.example_placeholder}
                    variant='outlined'
                    fullWidth
                    multiline
                    value={exampleQuestion}
                    onChange={onChangeExampleQuestion}
                    inputProps={{ maxLength: 1000 }}
                    helperText={lang.helper_texts.question}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                    {lang.exam_style_text}
                </Typography>
                <SelectionTags
                    tags={skills}
                    initClickedTags={selectedSkills}
                    onSubmitTags={onSelectTags}
                    lang={lang?.skills}
                    isRTL={isRTL}
                />
            </Grid>
        </Grid>
    )
}

export default PersonalizationInputs;
