
export const extractYouTubeVideoId = (url) => {
    if (url.trim() === '') {
        return null;
    }
    const videoIdRegex = /[?&]v=([^?&]+)|youtu\.be\/([^?&]+)/;
    const match = url.match(videoIdRegex);
    return match ? match[1] || match[2] : null;
};

export const displayParameters = (questions = [], difficulty = null, isRTL) => {
    const questionsAmount = questions.reduce((acc, question) => {
        if (question.type)
            acc[question.type] += 1
        return acc
    }, { multiple_choice: 0, open_questions: 0, fill_in_the_blank: 0, graph_questions: 0, nested_questions: 0 });
    const { multiple_choice, open_questions, fill_in_the_blank, graph_questions, nested_questions } = questionsAmount;
    const fields = [];

    if (multiple_choice && multiple_choice > 0) {
        fields.push(isRTL ? `שאלות סגורות: ${multiple_choice}` : `Multiple Choice: ${multiple_choice}`);
    }
    if (open_questions && open_questions > 0) {
        fields.push(isRTL ? `שאלות פתוחות: ${open_questions}` : `Open Questions: ${open_questions}`);
    }
    if (fill_in_the_blank && fill_in_the_blank > 0) {
        fields.push(isRTL ? `השלמת משפט: ${fill_in_the_blank}` : `Fill in the Blank: ${fill_in_the_blank}`);
    }
    if (graph_questions && graph_questions > 0) {
        fields.push(isRTL ? `שאלות גרף: ${graph_questions}` : `Graph Questions: ${graph_questions}`);
    }
    if (nested_questions && nested_questions > 0) {
        fields.push(isRTL ? `שאלות עם סעיפים: ${nested_questions}` : `Nested Questions: ${nested_questions}`);
    }

    if (difficulty && difficulty.trim() !== '') {
        if (isRTL) {
            fields.push(`רמת קושי: ${difficulty}`);
        } else {
            fields.push(`Difficulty: ${difficulty}`);
        }
    }

    return fields.join(', ');
};

export const displayPersonalizedData = (personalizedData, isRTL) => {
    const { focus, exampleQuestion } = personalizedData;
    const fields = [];

    if (focus) {
        fields.push(isRTL ? 'מיקוד: ' + focus : `Focus: ${focus}`);
    } else {
        fields.push(isRTL ? 'מומלץ להוסיף מיקוד לקבלת תוצאות טובות יותר' : 'We recommend adding focus for better results');
    }

    if (exampleQuestion) {
        fields.push(isRTL ? 'שאלה לדוגמא: ' + exampleQuestion : `Example Question: ${exampleQuestion}`);
    }

    return fields.join(', ');
};
