import api from "./api";
import { processResponsePromise } from "./utils";

export async function uploadImage(image) {
    const formData = new FormData();
    formData.append('image', image);
    return processResponsePromise(api.post('/images/upload', formData))
}

export async function deleteImage(imageId) {
    return processResponsePromise(api.post(`/images/delete`, { public_id: imageId }))
}