import React from 'react';
import { useLocation } from 'react-router-dom';
import HebrewLandingPage from './HebrewLandingPage/HebrewLandingPage';
import LandingPage from './LandingPage/LandingPage';
import { useLanguage, languageCodes } from '../contexts/languageContext';

const LandingPageManager = () => {
    const { isRTL } = useLanguage();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const langParam = queryParams.get('lang');

    // Determine which landing page to display based on query parameter and language direction
    if (langParam === languageCodes.hebrew || isRTL) {
        return <HebrewLandingPage />;
    }

    return <LandingPage />;
};

export default LandingPageManager;
