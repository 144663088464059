import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { School, Feedback, Assessment as AssessmentIcon, Timeline } from '@mui/icons-material';
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';
import FormativeAssessmentImg from '../../../assets/features/professor.jpg'; // Placeholder image

const FormativeAssessmentUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <AssessmentIcon color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Advanced Formative Assessment Tools">
                                    Advanced Formative Assessment Tools: Revolutionizing Student Learning, Test Preparation, and Academic Achievement with Cutting-Edge AI Technology
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Our advanced formative assessment tools are meticulously designed to revolutionize the way students learn and prepare for tests. By leveraging state-of-the-art AI technology, our platform provides educators and students with immediate, actionable feedback that drives continuous improvement and enhances academic performance. With our comprehensive suite of formative assessment tools, you can achieve a higher level of precision in evaluating student progress and performance.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                The power of AI in formative assessments lies in its ability to deliver real-time insights and analytics that help educators adapt their teaching strategies to meet individual student needs. Our platform supports a wide range of assessment types, including quizzes, practice tests, and diagnostic assessments, making it easier than ever to evaluate student performance and foster a culture of continuous learning and improvement.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                With real-time feedback and detailed analytics at your fingertips, our platform empowers educators to create personalized learning experiences that are tailored to each student's strengths and areas for growth. Whether you're preparing for standardized exams or creating customized assessments, our tools provide the flexibility and depth needed to enhance both learning outcomes and test preparation.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Key Features and Benefits of Our Comprehensive Formative Assessment Platform
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Feedback color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">AI-Enhanced Feedback for Assessments and Tests</Typography>
                                    </Box>
                                    Our platform integrates advanced AI algorithms to deliver instant, personalized feedback on a variety of assessments and tests. This cutting-edge technology allows students to gain a deep understanding of their performance, pinpoint specific areas for improvement, and refine their skills effectively. By providing real-time, actionable feedback, we enable students to continuously enhance their readiness for future academic challenges and improve their overall performance.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Timeline color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Comprehensive Tracking of Test Performance and Learning Progress</Typography>
                                    </Box>
                                    Track and analyze student performance comprehensively with our robust analytics tools. Our platform provides detailed insights into learning trends, performance metrics, and test results, enabling educators to make informed decisions that enhance test preparation and academic achievement. By monitoring performance over time, you can identify patterns, adjust teaching strategies, and provide targeted support to help students succeed.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <School color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Create an Effective Learning and Assessment Environment</Typography>
                                    </Box>
                                    Our platform simplifies the process of designing, managing, and evaluating assessments, providing you with the tools needed to create an effective learning environment. By offering constructive feedback and maintaining high academic standards, we help foster a supportive atmosphere that encourages student growth and success. Our tools also support the creation of customized assessments tailored to specific learning objectives, further enhancing the educational experience.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                Implementing Formative Assessments: A Comprehensive Guide for Educators to Maximize Learning Outcomes
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Implementing formative assessments with our platform is straightforward and highly effective. Our intuitive tools enable you to create, manage, and evaluate a wide range of assessments effortlessly. By leveraging advanced AI technology for personalized feedback, you can enhance learning outcomes, prepare students more effectively for tests, and drive academic success. Our platform provides a comprehensive solution for formative assessment, helping educators to meet the diverse needs of their students and achieve educational excellence.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                        {/* Image Column */}
                        {/* <Grid item xs={12}>
                            <img 
                                src={FormativeAssessmentImg} 
                                alt="Educator using formative assessment tools" 
                                style={{ width: '100%', borderRadius: 8, border: '1px solid black' }}
                                aria-label="Formative Assessment Image"
                            />
                        </Grid> */}
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default FormativeAssessmentUseCase;
