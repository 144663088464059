import React, { useState } from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import FileUploadHandler from '../../components/FileUploadHandlerForExam';
import { colors } from '../../utils/customTheme';
import { uploadPastExam } from '../../requests/exam';
import useSnackBar from '../../hooks/useSnackBar';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import { useLanguage } from '../../contexts/languageContext';

const UploadPastTestsStep = ({handleFileAdded}) => {
    const [uploadedText, setUploadedText] = useState(''); 
    const { openSnackBar } = useSnackBar();
    const {languageData} = useLanguage();
    // todo: Add it to RAG and save in Vector DB
    const handleInput = (text) => {
        setUploadedText(text);
        try {
            handleFileAdded(text);
            //uploadPastExam(text);
        } catch (error) {
            console.error('Error uploading past exam:', error);
            openSnackBar(SnackbarTypes.ERROR.field);
        }

    };

    return (
        <Grid container alignItems="center" spacing={2} >
            <Grid item xs={12}>
                <Typography variant="h5" align='center' gutterBottom sx={{ fontWeight: 'bold' }}>
                    {languageData?.onboarding?.upload_past_test_step?.title || "Upload Past Test (optional)"}
                </Typography>
                <Typography variant="subtitle1" align='center' gutterBottom>
                    {languageData?.onboarding?.upload_past_test_step?.subtitle || "Uploading past tests helps us understand your teaching style and the type of questions you prefer, enabling us to provide more personalized and effective exam suggestions."}
                </Typography>
            </Grid>
            {/* todo: sx={{ bgcolor: colors.onboarding }} */}
            <Grid item xs={12}>
                <FileUploadHandler onInput={handleInput} maxFiles={5} maxCharsLimit={30000} />
            </Grid>
        </Grid>
    );
};

export default UploadPastTestsStep;
