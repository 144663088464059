import React, { useState } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Select, Typography, Button } from '@mui/material';
import subjects from '../../utils/subjectsData';
import { useLanguage } from '../../contexts/languageContext';

const HubApproveDialog = ({ open, onClose, onApprove }) => {
    const { languageData, isRTL } = useLanguage();
    const [selectedSubject, setSelectedSubject] = useState('Other');

    const handleSubjectChange = (event) => {
        setSelectedSubject(event.target.value);
    };

    const handleApprove = () => {
        onApprove(selectedSubject);
    };

    return (
        <Dialog open={open} onClose={onClose} dir={isRTL ? 'rtl' : 'ltr'}>
            <DialogTitle>{languageData?.hub.dialog.title}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            {languageData?.hub.dialog.subtitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="textSecondary">
                            {languageData?.hub.dialog.select}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            value={selectedSubject}
                            onChange={handleSubjectChange}
                            fullWidth
                            displayEmpty
                            placeholder="Select Subject"
                        >
                            {subjects.map(subject => (
                                <MenuItem key={subject.subject} value={subject.subject}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ padding: '8px' }}>{subject.icon}</div>
                                        {isRTL ? subject.hebrewSubject : subject.subject}
                                    </div>
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {languageData?.hub.dialog.cancel}
                </Button>
                <Button onClick={handleApprove} variant='contained' color="primary">
                    {languageData?.hub.dialog.approve}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HubApproveDialog;
