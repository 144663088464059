import React, { useState, useContext } from 'react';
import { Avatar, Menu, MenuItem, ListItemIcon, Link, Divider } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person'; // Import PersonIcon
import { logout } from '../requests/auth';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { EMPTY_STRING } from '../utils';
import { allowFocusStyle, colors } from '../utils/customTheme';
import { useLanguage } from '../contexts/languageContext';

export default function AvatarProfile({ }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const { languageData, ToggleLanguage } = useLanguage()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleClick(event);
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logout().then(() => {
            userContext.logout();
            navigate('/');
        });
    };

    return (
        <div style={{ margin: 10 }}>
            <Avatar onClick={handleClick} onKeyDown={handleKeyDown}
                sx={{ cursor: 'pointer', ...allowFocusStyle, bgcolor: colors.secondary }}
                tabIndex='0' >
                {userContext?.user?.fullname?.substring(0, 1).toUpperCase() ?? EMPTY_STRING}
            </Avatar>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                tabIndex={0}
            >
                <MenuItem
                    component={Link}
                    href="/start"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleClose}
                >
                    <ListItemIcon>
                        <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    {languageData?.profile?.profile || 'Profile'}
                </MenuItem>
                <Divider />
                {userContext.isPremium() &&
                    <MenuItem
                        component={Link}
                        href="https://exam-generator.lemonsqueezy.com/billing"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClose}
                    >
                        <ListItemIcon>
                            <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        {languageData?.profile?.manage || 'Manage Subscription'}
                    </MenuItem>
                }
                {userContext.isPremium() && <Divider />}
                <MenuItem
                    component={Link}
                    onClick={() => { handleClose(); handleLogout(); }}
                >
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    {languageData?.profile?.logout || 'Logout'}
                </MenuItem>
            </Menu>
        </div>
    );
}
