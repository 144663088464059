import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { Chat, Grade, AssignmentTurnedIn } from '@mui/icons-material';
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const FeedbackGrades = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="flex-start">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <Chat color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" component="h1" sx={{ flexGrow: 1, fontSize: '35px' }} aria-label="AI-Powered Feedback and Grades for Online Assessments">
                                    Revolutionize Online Assessments with AI-Powered Feedback and Comprehensive Grades
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                In the realm of online education, the ability to provide timely and insightful feedback is crucial for student success. Our AI-driven feedback and grading system offers a transformative approach to online assessments, harnessing the power of advanced algorithms and cutting-edge analytics to deliver detailed grades and constructive feedback. Our platform is designed to enhance the learning experience by providing educators and students with a comprehensive view of performance and progress. By integrating AI technology, we offer a solution that not only streamlines the assessment process but also fosters a deeper understanding of student achievements and areas for improvement.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                Our innovative platform is equipped with features that cater to the evolving needs of online education. From providing personalized feedback that addresses individual learning gaps to generating comprehensive grades that reflect true student performance, our system is designed to support educators in delivering effective and insightful assessments. The advanced analytics capabilities of our platform enable a thorough analysis of student data, facilitating data-driven decision-making and enhancing the overall effectiveness of the educational process. Embrace the future of online assessments with our AI-powered solutions that bring precision, efficiency, and depth to feedback and grading practices.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px', mb: 2 }}>
                                    Why Opt for AI in Feedback and Grading?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 2, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Chat color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">AI-Driven Constructive Feedback</Typography>
                                    </Box>
                                    Our AI-driven feedback system is designed to provide personalized and actionable insights into student performance. By analyzing response patterns and common errors, our AI generates constructive feedback that helps students understand their mistakes, learn from them, and improve their skills. This personalized approach not only enhances the learning experience but also supports students in achieving their academic goals by addressing their unique learning needs and challenges.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 2, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Grade color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Detailed Grades and Comprehensive Analytics</Typography>
                                    </Box>
                                    Our platform provides detailed grades along with comprehensive analytics that offer a clear and actionable understanding of student performance. The AI-powered grading system ensures accuracy and consistency, while the analytics tools deliver insights into trends, strengths, and areas for improvement. This data empowers educators to tailor their teaching strategies, provide targeted support, and make informed decisions to enhance student outcomes and overall educational effectiveness.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <AssignmentTurnedIn color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Monitor and Track Student Performance</Typography>
                                    </Box>
                                    Our platform allows educators to continuously monitor and track student performance through AI-driven insights. By analyzing performance data over time, educators can identify trends, assess progress, and adjust teaching methods accordingly. This capability ensures that interventions are timely and effective, providing a data-driven approach to improving student achievement and supporting ongoing educational development.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px', mb: 2 }}>
                                    How to Integrate AI for Effective Feedback and Grading in Online Assessments
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                    To maximize the benefits of our AI-powered feedback and grading system, integrate it seamlessly into your online assessment process. Start by utilizing our tools to generate personalized feedback that aligns with each student's performance, and use our grading system to deliver accurate and detailed grades. Leverage the analytics features to gain a comprehensive understanding of student progress and adjust your teaching strategies as needed. By incorporating these AI-driven tools into your assessments, you can enhance the overall educational experience, streamline grading processes, and support student success with data-driven insights and feedback.
                                </Typography>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default FeedbackGrades;
