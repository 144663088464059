import React, { useState, useMemo } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, Chip, MenuItem, Select } from '@mui/material';
import generateEnglishPDF from '../assets/tutorials/generate.pdf';
import autoGradeEnglishPDF from '../assets/tutorials/grade.pdf';
import generateHebrewPDF from '../assets/tutorials/יצירה.pdf';
import autoGradeHebrewPDF from '../assets/tutorials/בדיקה.pdf';
import Header from '../components/Header';
import { Helmet } from 'react-helmet-async';

const tutorials = [
    {
        title: "How To Generate New Exam",
        subtitle: "Step-by-step guide on generating new exams",
        link: generateEnglishPDF,
        language: "En"
    },
    {
        title: "How To Auto-Grade Exam",
        subtitle: "Automate the grading process efficiently",
        link: autoGradeEnglishPDF,
        language: "En"
    },
    {
        title: "איך לייצר מבחן חדש",
        subtitle: "מדריך שלב אחר שלב ליצירת מבחן חדש",
        link: generateHebrewPDF,
        language: "He"
    },
    {
        title: "איך לבדוק אוטומטית מבחן",
        subtitle: "אוטומציה של תהליך בדיקת המבחן",
        link: autoGradeHebrewPDF,
        language: "He"
    }
];

const Tutorials = () => {
    const [selectedLanguage, setSelectedLanguage] = useState('En');

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    const filteredTutorials = useMemo(() => {
        return tutorials.filter(tutorial => tutorial.language === selectedLanguage);
    }, [selectedLanguage]);

    return (
        <div>
            <Helmet>
                <title>Exam Generator | Tutorials</title>
                <meta name="description" content="Explore our tutorials to master the AI-powered exam generator. Learn how to create personalized exams, customize questions, and optimize your workflow with our step-by-step guides." />
            </Helmet>
            <Header />
            <Container style={{ padding: '30px' }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Tutorials
                </Typography>
                <Select
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    style={{ marginBottom: '20px' }}
                >
                    <MenuItem value="En">English</MenuItem>
                    <MenuItem value="He">עברית</MenuItem>
                </Select>
                <Grid container spacing={3}>
                    {filteredTutorials.map((tutorial, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card>
                                <CardContent style={{ direction: tutorial.language === "He" ? 'rtl' : 'ltr' }}>
                                    <Typography variant="h5" component="h2">
                                        {tutorial.title}
                                    </Typography>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {tutorial.subtitle}
                                    </Typography>
                                    <div style={{ marginTop: '10px' }}>
                                        <Chip label={tutorial.language === "He" ? 'עברית' : 'English'} />
                                    </div>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        href={tutorial.link}
                                        target="_blank"
                                        style={{ marginTop: '16px' }}
                                    >
                                        {tutorial.language === "He" ? 'לצפייה במדריך' : 'View Tutorial'}
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
};

export default Tutorials;
