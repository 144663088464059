import React from 'react';
import { Paper, Box } from '@mui/material/';
import PersonalizationInputs from './PersonalizationInputs';
import ContentToggle from '../ContentToggle';
import IndicatorToggle from '../IndicatorToggle';

const PersonalizedButton = ({ handlePersonalizion, personalizedData, isCloseContent, handleToggleClosedContent, useIndicator, handleToggleIndicator, indicatorProps }) => {
    const handleSubmit = (examSkills, examFocus, exampleQuestion) => {
        handlePersonalizion(examSkills, examFocus, exampleQuestion);
    };

    return (
        <Paper sx={{ display: 'flex', flexDirection: 'column', margin: 3, padding: 2 }}>
            <PersonalizationInputs handleSubmit={handleSubmit} personalizedData={personalizedData} />
            <Box mt={0} ml={3}>
                <ContentToggle isCloseContent={isCloseContent} handleToggleChange={handleToggleClosedContent} />
            </Box>
            <Box mt={0} ml={3}>
                <IndicatorToggle
                    isIndicatorOn={useIndicator}
                    handleToggleChange={handleToggleIndicator}
                    {...indicatorProps}
                />
            </Box>
        </Paper>
    );
};

export default PersonalizedButton;
