import { HttpStatusCode } from 'axios';
import api from './api';

/**
 * Get good questions for a user.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<Array<{text: string, doc_id: string}>>} - The list of good questions.
 * @throws {Error} - If failed to get good questions.
 */
export const getUserGoodQuestions = async (maxQuestions) => {
    try {
        const response = await api.get(`/personalization`);
        if (response.status === HttpStatusCode.Ok) {
            const slicedData = response.data.slice(0, maxQuestions);           
            return slicedData;
        } else {
            throw new Error('Failed to get good questions');
        }
    } catch (error) {
        throw error;
    }
}


export const updateGoodQuestion = async (questionId, updatedQuestion) => {
    try {
        const response = await api.put(`/personalization/${questionId}`, { question: updatedQuestion });
        if (response.status === HttpStatusCode.Ok) {   
            return response.data;
        } else {
            throw new Error('Failed to update good question');
        }
    } catch (error) {
        throw error;
    }
}
export const deleteGoodQuestion = async (questionId) => {
    try {
        const response = await api.delete(`/personalization/${questionId}`);
        if (response.status === HttpStatusCode.Ok) {
            return response.data;
        } else {
            throw new Error('Failed to delete good question');
        }
    } catch (error) {
        throw error;
    }
}

export const addGoodQuestion = async (question) => {
    try {
        const response = await api.post(`/personalization`, { question });
        if (response.status === HttpStatusCode.Created) {
            return response.data;
        } else {
            throw new Error('Failed to create good question');
        }
    } catch (error) {
        throw error;
    }
}
