export const SnackbarTypes = {
    SUCCESS: { msg: "Exam is ready!", hebrew_msg: "המבחן מוכן", severity: 'success' },
    ERROR: { msg: "Something went wrong, please try again or refresh", hebrew_msg: "משהו השתבש, אנא נסה שוב או רענן את העמוד", severity: 'error' },
    FORM_INVALID: { msg: "Form Submitted is invalid", hebrew_msg: "אחד השדות שגוי או חסר", severity: 'error' },
    WAITING: { msg: "Generation may take up to 5 minutes", hebrew_msg: "היצירה עשויה לקחת עד 5 דקות", severity: 'info' },
    WAITING2: { msg: "Creating structure...", hebrew_msg: "יוצר את מבנה המבחן", severity: 'info' },
    WAITING3: { msg: "Phrasing questions...", hebrew_msg: "מנסח שאלות", severity: 'info' },
    WAITING4: { msg: "Almost done...", hebrew_msg: "כמעט וסיימנו, היצירה בשלבי סיום", severity: 'info' },
    HISTORY: { msg: 'Exam pushed to history', hebrew_msg: 'המבחן נכנס להיסטוריה', severity: 'success' },
    LOGIN_FAILED: { msg: 'Login Failed.', hebrew_msg: 'ההתחברות נכשלה', severity: 'error' },
    LOGGED_IN: { msg: 'You Are Logged In', hebrew_msg: 'התחברת בהצלחה', severity: 'success' },
    INVALID_CREDENTIALS: { msg: 'Please enter valid email and password', hebrew_msg: 'נא להזין אימייל וסיסמה תקניים', severity: 'error' },
    VERIFY_EMAIL_BEFORE_LOGIN: { msg: 'You must verify your email before logging in. Check your email for the verification link.', hebrew_msg: 'נא לאמת את האימייל לפני התחברות. יש לבדוק את האימייל לקישור האימות.', severity: 'warning' },
    DELETE_EXAM: { msg: 'Deleting...', hebrew_msg: 'מוחק', severity: 'info' },
    EXAM_DELETED: { msg: 'Deleted Successfully', hebrew_msg: 'נמחק בהצלחה', severity: 'success' },
    LIMIT: { msg: 'Oops, text is too long', hebrew_msg: 'הטקסט ארוך מדי', severity: 'error' },
    FILE_LIMIT: { msg: 'Files are too large. Last file will be truncated', hebrew_msg: 'התוכן ארוך ולכן יקוצר לגבול האותיות', severity: 'warning', duration: 8000 },
    NO_TEXT: { msg: 'Please insert text or file to create an exam', hebrew_msg: 'נא להכניס טקסט או קובץ כדי ליצור מבחן', severity: 'error' },
    TRANSCRIPT: { msg: 'YouTube transcript is too long', hebrew_msg: 'הטקסט מהסרטון יוטיוב ארוך מדי', severity: 'error' },
    INVALID_YOUTUBE_ID: { msg: 'YouTube link is invalid', hebrew_msg: 'הלינק של יוטיוב לא תקין', severity: 'error' },
    VALID_YOUTUBE_ID: { msg: 'YouTube link is Valid', hebrew_msg: 'הלינק תקין, נסו ליצור מבחן', severity: 'success' },
    UNAUTHORIZED_SIGNUP: { msg: 'Oops! Organization sigup has failed. Please contact support.', hebrew_msg: 'אופס! נראה שההרשמה לארגון נכשלה. צור קשר עם התמיכה לקבלת עזרה.', severity: 'error' },
    ORG_CREATED_SUCCESS: { msg: 'Organization Created Successfully!', hebrew_msg: 'הארגון נוצר בהצלחה!', severity: 'success' },
    ORG_CREATED_FAILED: { msg: 'Creating Organization Failed.', hebrew_msg: 'יצירת הארגון נכשלה', severity: 'error' },
    ORG_UPDATED_SUCCESS: { msg: 'Organization Updated Successfully!', hebrew_msg: 'הארגון עודכן בהצלחה!', severity: 'success' },
    ORG_UPDATED_FAILED: { msg: 'Updating Organization Failed', hebrew_msg: 'עדכון הארגון נכשל', severity: 'error' },
    NO_SUBTITLES: { msg: 'YouTube video must have subtitles', hebrew_msg: 'לסרטון אין כתוביות, לא ניתן לקרוא מידע', severity: 'error' },
    CONFLICT: { msg: 'Email already registered. Contact support for further assistance', hebrew_msg: 'האימייל בשימוש, לתמיכה צרו קשר', severity: 'error' },
    RESET_PASSWORD: { msg: 'Reset code will be sent to your email, if the email exists', hebrew_msg: 'קוד שחזור יישלח למייל, אם המייל קיים במערכת', severity: 'info' },
    INVALID_RESET_CODE: { msg: 'The code you entered might be invalid', hebrew_msg: 'ייתכן והקוד שהוכנס אינו תקין', severity: 'error' },
    RESET_PASSWORD_SUCCEEDED: { msg: 'Password was reset successfully, you are being redirected...', hebrew_msg: 'איפוס סיסמא עבר בהצלחה, את/ה מועבר להתחברות', severity: 'success' },
    SIGNUP_FIRST: { msg: 'Saving Failed. Please Sign-Up first...', hebrew_msg: 'נא להירשם קודם, השמירה נכשלה', severity: 'error' },
    SAVE_SUCCESS: { msg: 'Successful Saving. Welcome Aboard', hebrew_msg: 'השמירה הצליחה! ברוך הבא', severity: 'success' },
    SUBMIT_SUCCESS: { msg: 'Successful Submission', hebrew_msg: 'השליחה הצליחה', severity: 'success' },
    SUBMIT_FAILED: { msg: 'Submission Failed', hebrew_msg: 'השליחה נכשלה', severity: 'error' },
    SAVED_FOR_LATER_SUCCESS: { msg: 'Saved Successfuly', hebrew_msg: 'נשמר בהצלחה', severity: 'success' },
    SAVED_FOR_LATER_FAILED: { msg: 'Saving for later failed', hebrew_msg: 'השמירה נכשלה', severity: 'error' },
    FETCH_FAILED: { msg: 'Fetching Failed', hebrew_msg: 'הבאת המידע נכשלה', severity: 'error' },
    NO_EXAM_ID: { msg: 'Invalid Exam ID. Make sure link is correct', hebrew_msg: 'מזהה מבחן לא חוקי. ודאו כי הלינק תקין', severity: 'error' },
    GRADE_ERROR: { msg: 'One of the grades you gave is invalid', hebrew_msg: 'אחד הציונים שנתת לא תקין', severity: 'error' },
    COPIED_LINK: { msg: 'Link Has Been Copied', hebrew_msg: 'הלינק הועתק בהצלחה', severity: 'success' },
    VALIDATION_FAILED: { msg: 'Validation Failed', hebrew_msg: 'האימות נכשל', severity: 'error' },
    RESEND_FAILD: { msg: 'Resend Failed', hebrew_msg: 'השליחה נכשלה', severity: 'error' },
    APPROVE_EXAM_TO_HUB: { msg: 'Your Exam has been uploaded to the Hub!', hebrew_msg: 'המבחן הועלה למאגר בהצלחה!', severity: 'success' },
    AFTER_REMIX: { msg: `Exam remixed successfully!\n You can change the exam's parameters \n When you finish click on Generate Exam`, hebrew_msg: `נתוני המבחן הוזנו בהצלחה! \n שנו את הנתונים במידת הצורך. \n בסיום, לחצו על יצירת מבחן.`, severity: 'success' },
    AI_REPORT_FAILED: { msg: `Creating AI report has failed!`, hebrew_msg: `יצירת דוח התלמיד נכשלה!`, severity: 'error' },
    FETCHIG: { msg: `Fetching...`, hebrew_msg: `טוענים`, severity: 'info' },
    READING_FILE: { msg: 'Reading file...', hebrew_msg: 'קורא את הקובץ', severity: 'info' },
    CHECK_FILE: { msg: 'Check file - no images or scanned', hebrew_msg: 'ודאו שאין תמונות או מסמכים סרוקים בקובץ', severity: 'error' },
    FILE_READY: { msg: 'File is ready', hebrew_msg: 'הקובץ מוכן', severity: 'success' },
    ONBOARDING_DATA_SAVED_SUCCESS: { msg: 'Onboarding data saved successfully', hebrew_msg: 'נתוני ההתחברות נשמרו בהצלחה', severity: 'success' },
    FILE_TYPE_NOT_SUPPORTED: { msg: 'File type is not supported, Images only', hebrew_msg: 'סוג הקובץ לא נתמך, נא להעלות תמונות בלבד', severity: 'error' },
};

Object.keys(SnackbarTypes).forEach(key => {
    SnackbarTypes[key].field = key
})
