import React from 'react';
import { Card, CardContent, CardActions, Button, Typography, Grid, Chip, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import GradingIcon from '@mui/icons-material/Grading';
import QuizIcon from '@mui/icons-material/Quiz';
import { useNavigate } from 'react-router-dom';
import useSnackBar from '../hooks/useSnackBar';
import { SnackbarTypes } from '../utils/snackbarTypes';
import { useLanguage } from '../contexts/languageContext';
import ShareButton from './Share/ShareButton';
import PeopleIcon from '@mui/icons-material/People';
import { capitalizeFirstLetter } from '../utils';

const ExamCardStyle = {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '16px',
    backgroundColor: '#fff',
};

const shareButtonStyle = {
    border: 'none', // Remove border
    color: '#0009', // Set text color to grey
    '&:hover': {
        color: '#0009', // Set text color to grey on hover
        border: 'none', // Remove border
        backgroundColor: '#0001', // Make background transparent on hover
    },
};

const ExamCard = ({ title = "Exam Title", date, id = "", deleteExam, responsesCount = null, totalQuestions = null, difficulty = null, source = null }) => {
    const navigate = useNavigate();
    const { openSnackBar } = useSnackBar();
    const { languageData } = useLanguage();

    const moveToPreview = () => {
        if (!id) {
            openSnackBar(SnackbarTypes.ERROR.field);
            return;
        }
        navigate(`/preview?id=${id}`);
    };

    const moveToReview = () => {
        if (!id) {
            navigate('/exam');
            return;
        }
        navigate(`/review?id=${id}`);
    };

    const handleDeleteExam = () => {
        deleteExam(id);
    };

    return (
        <Card style={ExamCardStyle}>
            <CardContent sx={{ flex: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Box>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            {title}
                        </Typography>
                        {date && (
                            <Typography variant="subtitle2" color="textSecondary" sx={{ marginTop: '8px' }}>
                                Date: {date}
                            </Typography>
                        )}
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginTop: '12px' }}>
                            {totalQuestions && (
                                <Chip label={`${totalQuestions} Questions`} variant="outlined" />
                            )}
                            {difficulty && (
                                <Chip label={difficulty} variant="outlined" />
                            )}
                            {source && (
                                <Chip label={capitalizeFirstLetter(source)} variant="outlined" />
                            )}
                        </Box>
                    </Box>
                    <IconButton aria-label="delete" onClick={handleDeleteExam}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
                <div style={{ paddingLeft: '25px', display: 'flex', minHeight: '25px', marginTop: '16px' }}>
                    {(responsesCount && responsesCount !== 0) ? <>
                        <PeopleIcon style={{ marginRight: '8px' }} />
                        <Typography variant="body1">{responsesCount}</Typography>
                    </> : null}
                </div>
            </CardContent>
            <CardActions sx={{ justifyContent: 'space-between', padding: '16px' }}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button
                            sx={{ textTransform: 'capitalize' }}
                            size="small"
                            variant="contained"
                            target="_blank"
                            rel="noopener noreferrer"
                            startIcon={<QuizIcon />}
                            onClick={moveToPreview}
                        >
                            {languageData?.preview_btn_label}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            size="small"
                            variant="contained"
                            color="success"
                            target="_blank"
                            rel="noopener noreferrer"
                            startIcon={<GradingIcon />}
                            onClick={moveToReview}
                        >
                            {languageData?.review_btn_label}
                        </Button>
                    </Grid>
                </Grid>
                {id && <ShareButton examId={id} languageData={languageData.preview} forceMobileStyle customButtonStyle={shareButtonStyle} />}
            </CardActions>
        </Card>
    );
};

export default ExamCard;
