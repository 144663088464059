import React, { useMemo } from 'react';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Card, CardContent, Typography, Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useLanguage } from '../../contexts/languageContext';


const SubjectList = ({ subjects, onSubjectSelect, exams }) => {
    const subjectColors = ['#D37676', '#A5D6A7', '#81D4FA', '#FFAB91', '#496989', '#15E1A5', '#FFB74D', '#B39DDB', '#50727B', '#747264', '#503C3C'];
    const { isRTL, languageData } = useLanguage()

    // Memoize the sorted subjects array
    const sortedSubjects = useMemo(() => {
        const sortedArray = [...subjects].sort((a, b) => {
            // "Other" should always be at the end
            if (a.subject === "Other") return 1;
            if (b.subject === "Other") return -1;
            return a.subject.localeCompare(b.subject);
        });
        return sortedArray;
    }, [subjects]);

    const countExamsForSubject = (subject) => {

        // Filter the exams array to get only exams with the current subject
        const examsWithSubject = exams.filter(item => item.subject === subject.subject);
        // Return the length of the filtered array, which represents the number of exams
        return examsWithSubject.length;
    };
    const handleFocusTransition = (e) => {
        e.currentTarget.style.transform = 'scale(1.07)';
        e.currentTarget.style.boxShadow = '0 4px 20px rgba(0, 0, 0, 0.1)';
    }
    const handleBlurTransition = (e) => {
        e.currentTarget.style.transform = 'scale(1)';
        e.currentTarget.style.boxShadow = 'none';
    }

    return (
        <List>
            <Grid container spacing={2}>
                {sortedSubjects.map((subject, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4}>
                        <Card
                            elevation={0}
                            style={{
                                margin: '8px',
                                borderRadius: '12px',
                                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer'
                            }}
                            tabIndex={0}
                            onMouseEnter={handleFocusTransition}
                            onFocus={handleFocusTransition}
                            onMouseLeave={handleBlurTransition}
                            onBlur={handleBlurTransition}
                            onClick={() => onSubjectSelect(subject)}
                            onKeyDown={(e) => e.key === 'Enter' ? onSubjectSelect(subject) : null}
                        >
                            <CardContent
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    flexGrow: 1
                                }}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    borderRadius="50%"
                                    bgcolor={alpha(subjectColors[index % subjectColors.length], 0.2)} // Lighter background color
                                    width={60}
                                    height={60}
                                    mb={2}
                                    position="relative"
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <ListItemIcon style={{ color: subjectColors[index % subjectColors.length], fontSize: '2rem', minWidth: 0 }}>
                                            {subject.icon}
                                        </ListItemIcon>
                                    </Box>
                                </Box>
                                <ListItemText
                                    primary={
                                        <Typography variant="h6">
                                            {isRTL ? subject.hebrewSubject : subject.subject}
                                        </Typography>
                                    }
                                    secondary={`${languageData?.hub?.subtitle} ${countExamsForSubject(subject)}`}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </List>
    );
};

export default SubjectList;
