import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HttpStatusCode } from 'axios';
import Identification from './Identification';
import Exam from './Exam';
import ThankYou from './ThankYou';
import { requestSubmitExam } from '../../requests/quiz';
import useSnackBar from '../../hooks/useSnackBar';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import { languageCodes, useLanguage } from '../../contexts/languageContext';
import { useLoader } from '../../hooks/useLoader';
import { getTempToken } from '../../requests/token';
import { getExamForQuiz } from '../../requests/quiz';
import { languages, questionsType } from '../../utils/examOptions';
import { Alert, Typography } from '@mui/material';
import SupervisionApprove from './SupervisionApprove';
import { colors } from '../../utils/customTheme';
import { requestEvaluation } from '../../requests/exam';
import { roundToTwoDecimalPlaces } from '../../utils';

const Quiz = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const examId = queryParams.get('id');

  const [step, setStep] = useState(1);
  const [studentDetails, setStudentDetails] = useState(null); //student data
  const [examJson, setExamJson] = useState(null);
  /** @type {[{questionId: string, answer: string}[], Function]} */
  const [examAnswers, setExamAnswers] = useState(null);
  const [showSupervisionDialog, setShowSupervisionDialog] = useState(true);
  const { openLoader, closeLoader } = useLoader()
  const { openSnackBar } = useSnackBar();
  const { languageData, isRTL, ToggleLanguage, fetchLanguageWithToken } = useLanguage()
  const responseIdRef = useRef(null);
  const token = useRef(null)

  useEffect(() => {
    getTempToken(null).then(tok => {
      if (tok) {
        token.current = tok
        fetchLanguageWithToken(tok)
      }

      fetchExamJson()
    })
  }, [])

  useEffect(() => {
    handleSettings()
  }, [examJson])

  const handleSettings = () => {
    if (!examJson) return;
    const { settings } = examJson;

    if (!settings?.identification_required) {
      // Start Exam without identification
      const defaultStudentData = {
        fullName: "---",
        id: "---",
        email: ""
      };
      handleStartExam(defaultStudentData);
    }
  };

  const fetchExamJson = async () => {
    openLoader()
    try {
      const data = await getExamForQuiz(examId, token.current);
      if (!data) throw Error('Request failed')
      setExamJson(data);
      if (data?.parameters?.language === languages.Hebrew)
        ToggleLanguage(languageCodes.hebrew, token.current)
    } catch (error) {
      openSnackBar(SnackbarTypes.NO_EXAM_ID.field)
      console.error('Error fetching exam data:', error);
    }
    closeLoader()
  };

  const handleStartExam = async (data) => {
    // fetch token
    const tok = await getTempToken({ ...data, examId })
    if (!tok) {
      openSnackBar(SnackbarTypes.ERROR.field)
      return;
    }
    token.current = tok
    // fetch languages & exam
    Promise.all([
      fetchLanguageWithToken(tok),
    ]).then(() => {
      // Set student data
      setStudentDetails(data);
      setStep(2);
    })
  };

  const handleFinishExam = async (answers, questions) => {
    openLoader()
    /** @type {[{questionId: string, question: string, answer: string}[], Function]} */
    const formattedRequest = formatExamAnswers(answers, questions);
    setExamAnswers(formattedRequest);

    try {
      const submitExamResponse = await requestSubmitExam(examId, formattedRequest, studentDetails, token.current);
      if (submitExamResponse.status === HttpStatusCode.Ok) {
        openSnackBar(SnackbarTypes.SUBMIT_SUCCESS.field);
        responseIdRef.current = submitExamResponse.data?.response?._id;
      } else
        openSnackBar(SnackbarTypes.SUBMIT_FAILED.field);
      setStep(3)
    }
    catch (error) {
      console.error(error);
      openSnackBar(SnackbarTypes.SUBMIT_FAILED.field);
    }
    closeLoader()
  };

  const handleDialogClose = () => {
    setShowSupervisionDialog(false);
  };

  const handleDialogAccept = () => {
    setShowSupervisionDialog(false);
  };

  return (
    <div style={{ backgroundColor: colors.onboarding, minHeight: '100vh' }}>
      {examJson?.settings?.is_supervised && (
        <>
          <Alert severity="warning">
            <Typography variant="body2" fontWeight="bold">
              {languageData?.quiz?.supervision_notice}
            </Typography>
          </Alert>
          <SupervisionApprove
            open={showSupervisionDialog}
            onClose={handleDialogClose}
            onAccept={handleDialogAccept}
          />
        </>
      )}

      {step === 1 && <Identification title={examJson?.title} settings={examJson?.settings} onStart={handleStartExam} />}
      {step === 2 && <Exam examJson={examJson} onFinish={handleFinishExam} token={token.current} />}
      {step === 3 && <ThankYou examId={examJson._id} responseId={responseIdRef.current} showResults={examJson?.settings?.show_results} languageData={languageData.quiz} isRTL={isRTL} studentDetails={studentDetails} examAnswers={examAnswers} />}
    </div>
  );
};

export default Quiz;

function formatExamAnswers(answers, questions) {
  return Object.keys(answers).map(questionId => ({
    questionId: questionId,
    question: questions.find(q => q._id === questionId)?.question || '',
    [Array.isArray(answers[questionId]) ? "nested_answers" : "answer"]: answers[questionId]
  }));
}
