import React, { useState, useEffect } from "react";
// Hooks
import { useNavigate } from 'react-router-dom'
import { useLanguage } from '../../contexts/languageContext'
// MUI
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Typography, Divider } from '@mui/material';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
// Components
import EditableQuestionTypeManager from "../PreviewPage/EditableQuestionTypeManager";
// Utils
import { requestExamHub } from "../../requests/hub"
import { generateId } from "../../utils";
import Unseen from "../../components/Unseen";
import ChooseLanguageBtn from "../../components/ChooseLanguageBtn";

function PreviewDialog({ open, handleClose, examId }) {
    const [examJson, setExamJson] = useState(null);

    const { languageData } = useLanguage();
    const navigate = useNavigate()

    useEffect(() => {
        if (open) {
            const fetchExamJson = async () => {
                try {
                    const data = await requestExamHub(examId);
                    setExamJson(data);
                } catch (error) {
                    console.error("Error fetching exam JSON:", error);
                }
            };

            fetchExamJson();
        }
    }, [open, examId]);

    const handleCloseDialog = () => {
        handleClose();
    };

    const handleRemix = () => {
        const { prompt, focus, questions, bloomsArray, difficulty, language, source, skills } = examJson.parameters;
        // Determine if the source is a URL or contains math content
        const isURL = source === 'url'
        const isMath = source === 'math'

        const inputSource = (source && (isURL || isMath)) ? source : 'text';

        const remixData = {
            prompt: prompt ?? '',
            focus: focus ?? '',
            questions: bloomsArray ?? questions ?? '',
            difficulty: difficulty ?? '',
            language: language ?? '',
            skills: skills ?? [],
            source: source ?? '',
        };

        sessionStorage.setItem('remix', JSON.stringify(remixData));
        navigate(`/exam?input=${inputSource}`);
    };

    const direction = examJson?.parameters?.language !== 'English' ? 'rtl' : 'ltr'
    return (
        <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="lg" dir={direction}>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 8px' }}>
                <DialogTitle>{direction === 'rtl' ? "צפייה מקדימה" : "Preview Exam"}</DialogTitle>
                <ChooseLanguageBtn />
            </div>
            {!examJson && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>)}

            {examJson && (
                <DialogContent dividers>
                    <Typography variant="h5">{examJson.title}</Typography>
                    <Divider sx={{ marginY: '1rem' }} />
                    {examJson?.text && <Unseen text={examJson.text} />}
                    {examJson.questions?.map((question, index) => (
                        <React.Fragment key={index}>
                            <Typography key={generateId()} variant="h5" mt={2}>
                                {languageData.preview.question + ' ' + (index + 1)}
                            </Typography>
                            <EditableQuestionTypeManager
                                key={index}
                                type={question.type}
                                allQuestionsProps={{
                                    languageData: languageData.preview,
                                    questionIndex: index,
                                    title: question.question,
                                    grade: question.grade,
                                }}
                                nestedProps={{
                                    explanation: question.explanation,
                                    nested: question?.nested_questions,
                                    parentIndex: index + 1,
                                }}
                                openQuestionProps={{
                                    explanation: question.explanation,
                                    indicator: question?.indicator,
                                    source: question?.source_link,
                                }}
                                closeQuestionProps={{
                                    options: question.options,
                                    correctAnswer: question.correctAnswers?.[0],
                                }}
                                GraphQuestionProps={{
                                    explanation: question.explanation,
                                    functions: question.functions,
                                }}
                            />
                        </React.Fragment>
                    ))}
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={handleRemix} variant="contained" color="primary" startIcon={<SwitchAccessShortcutIcon />} sx={{ gap: '5px', '& .MuiButton-startIcon': { margin: 'auto' } }}>
                    {languageData?.remixButtonText ?? 'Remix'}
                </Button>
                <Button onClick={handleCloseDialog} color="primary">
                    {languageData?.closeButtonText ?? 'Close'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PreviewDialog;
