import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoader } from "../../hooks/useLoader.js";
import useSnackBar from "../../hooks/useSnackBar.js";
import { SnackbarTypes } from "../../utils/snackbarTypes.js";
import { getUserProfile } from '../../requests/user.js'
import { Button, Grid, Typography } from "@mui/material";
import { duplicateExam } from "../../requests/exam.js";
import ShareGif from '../../assets/share.gif'
import { getTempToken } from "../../requests/token.js";

function ShareRedirect() {
    // Hooks:
    const { openLoader, closeLoader } = useLoader()
    const { openSnackBar } = useSnackBar()
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const examId = queryParams.get('eid');
    const userId = queryParams.get('uid'); // colleague id
    // States:
    const [colleagueProfile, setColleagueProfile] = useState(null)
    const [redirectReady, setRedirectReady] = useState(false)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        openLoader()
        getTempToken({ examId, userId }).then(tempToken => {
            if (tempToken) {
                getUserProfile(userId, tempToken).then(profile => {
                    setColleagueProfile(profile)
                    closeLoader()
                })
            }
        })
        duplicateExam(examId, userId).then(new_eid => {
            if (new_eid) {
                setTimeout(() => {
                    navigate(`/preview?id=${new_eid}`)
                }, 2000)
                setRedirectReady(true)
            } else {
                openSnackBar(SnackbarTypes.ERROR.field)
                setIsError(true)
            }
        }).catch(e => setIsError(true))
    }, [])

    const handleRedirectClick = () => {
        if (redirectReady)
            navigate(`/preview?id=${new_eid}`)
    }

    if (colleagueProfile)
        return (
            <div style={{ height: '100vh', backgroundColor: '#fff' }}>
                <Grid container spacing={3} justifyContent="center" alignItems="center" p={5} pt={10}>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Typography variant="h3">
                            {colleagueProfile.fullname ?? colleagueProfile.email} has shared an exam with you!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center', paddingTop: '40px' }}>
                        <Typography variant="body">
                            Please wait, You are being redirected...
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center', paddingTop: '10px' }}>
                        <Button variant="text" sx={{ maxWidth: '400px' }} disabled={!redirectReady} onClick={handleRedirectClick}>
                            {redirectReady ? 'Click here if you are not redirected' : 'Please wait...'}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <img src={ShareGif} alt='Share' style={{ width: '100%', maxWidth: '250px', height: 'auto' }} />
                    </Grid>
                </Grid>
            </div>
        )
    else {
        if (isError)
            return (
                <Typography variant="h3" sx={{ textAlign: 'center' }}>
                    Something went wrong...
                </Typography>
            )
        else
            return null; // Render nothing if user is not yet fetched
    }
}

export default ShareRedirect;
