import React, { useState, useEffect, useRef } from 'react';
import { Container, Grid, Typography, Box, Divider } from '@mui/material';
import { landingStyles } from './Styles';

const styles = {
  container: {
    textAlign: 'center',
  },
  section: {
    background: '#131921',
    color: '#fff',
    paddingTop: '50px',
    paddingBottom: '50px',
    width: '100%',
  },
  countItem: {
    padding: '20px',
    borderRadius: '10px',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  counterNumber: {
    fontSize: '2rem',
    color: '#fff',
    fontFamily: landingStyles.fontFamily,
  },
  counterTitle: {
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: '10px',
    fontFamily: landingStyles.fontFamily,
  },
  divider: {
    margin: '20px 0',
    width: 'auto',
    height: 'auto',
    background: '#fff',
    orientation: 'vertical',
  },
};

const CounterParallax = () => {
  const [counters, setCounters] = useState({
    users: 0,
    examsCreated: 0,
    teachers: 0,
    languagesSupported: 0,
  });

  const targetValues = {
    users: 60000,
    examsCreated: 100000,
    teachers: 1000,
    languagesSupported: 10,
  };

  const componentRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        const interval = setInterval(() => {
          setCounters((prevCounters) => ({
            users: Math.min(prevCounters.users + 10, targetValues.users),
            examsCreated: Math.min(prevCounters.examsCreated + 10, targetValues.examsCreated),
            teachers: Math.min(prevCounters.teachers + 10, targetValues.teachers),
            languagesSupported: Math.min(prevCounters.languagesSupported + 1, targetValues.languagesSupported),
          }));
        }, 25);

        return () => clearInterval(interval);
      }
    }, options);

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => observer.disconnect();
  }, [componentRef, targetValues]);

  return (
    <div id='highlights' ref={componentRef} style={{ 'scroll-margin-top': '75px' }}>
      <Box sx={styles.section}>
        {/* ***** Counter Parallax Start ***** */}
        <Container sx={styles.container}>
          <Grid container spacing={0} justifyContent="center">
            {/* Happy Clients Counter */}
            <Grid item xs={12} md={3}>
              <div style={styles.countItem}>
                <Typography variant="h3" style={styles.counterNumber}>
                  <strong>{counters.examsCreated.toLocaleString()}+</strong>
                </Typography>
                <Typography variant="subtitle1" style={styles.counterTitle}>
                  Exams Created
                </Typography>
              </div>
            </Grid>
            {/* Project Counter */}
            <Grid item xs={12} md={3}>
              <div style={styles.countItem}>
                <Typography variant="h3" style={styles.counterNumber}>
                  <strong>{counters.users.toLocaleString()}+</strong>
                </Typography>
                <Typography variant="subtitle1" style={styles.counterTitle}>
                  Users
                </Typography>
              </div>
            </Grid>
            {/* Awards Wins Counter */}
            <Grid item xs={12} md={3}>
              <div style={styles.countItem}>
                <Typography variant="h3" style={styles.counterNumber}>
                  <strong>{counters.teachers.toLocaleString()}+</strong>
                </Typography>
                <Typography variant="subtitle1" style={styles.counterTitle}>
                  Teachers
                </Typography>
              </div>
            </Grid>
            {/* Countries Counter */}
            <Grid item xs={12} md={3}>
              <div style={styles.countItem}>
                <Typography variant="h3" style={styles.counterNumber}>
                  <strong>{counters.languagesSupported.toLocaleString()}+</strong>
                </Typography>
                <Typography variant="subtitle1" style={styles.counterTitle}>
                  Languages Supported
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
        {/* ***** Counter Parallax End ***** */}
      </Box>
    </div>
  );
};

export default CounterParallax;
