import React from 'react';
import { Box, Typography, TextField, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { colors } from '../../utils/customTheme';
import { useLanguage } from '../../contexts/languageContext';

const DetailsInputStep = ({ institutionType, setInstitutionType, institutionName, setInstitutionName, level, setLevel }) => {
    const { languageData } = useLanguage();
    
    if (institutionType === "Higher Education") {
        setLevel("Academic");
    }
    return (
        <Box sx={{ bgcolor: colors.onboarding, minHeight: '100vh' }}>
            <Typography variant="h5" component="h2" gutterBottom style={{ fontWeight: 'bold' }}>
                {languageData?.onboarding?.details_input_step?.institution_details || 'Institution Details'}
            </Typography>
            <FormControl fullWidth margin="normal">
                <InputLabel>{languageData?.onboarding?.details_input_step?.institution_type || 'Institution Type'}</InputLabel>
                <Select
                    value={institutionType}
                    onChange={(e) => setInstitutionType(e.target.value)}
                    label="Institution Type"
                    sx={{ backgroundColor: 'white' }}
                >
                    <MenuItem value="K-12 School">{languageData?.onboarding?.details_input_step?.k12_school || 'K-12 School'}</MenuItem>
                    <MenuItem value="Government">{languageData?.onboarding?.details_input_step?.government || 'Government'}</MenuItem>
                    <MenuItem value="Higher Education">{languageData?.onboarding?.details_input_step?.higher_education || 'Higher Education'} ({languageData?.onboarding?.details_input_step?.university || 'University'}/ {languageData?.onboarding?.details_input_step?.college || 'College'})</MenuItem>
                    <MenuItem value="Bootcamp">{languageData?.onboarding?.details_input_step?.bootcamp || 'Bootcamp'}</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
                <InputLabel>{languageData?.onboarding?.details_input_step?.education_level || 'Education Level'}</InputLabel>
                <Select
                    value={level}
                    onChange={(e) => setLevel(e.target.value)}
                    label="Level"
                    sx={{ backgroundColor: 'white' }}
                >
                    <MenuItem value="1st Grade">{languageData?.levelOptions[0].label || "1st Grade"}</MenuItem>
                    <MenuItem value="2nd Grade">{languageData?.levelOptions[1].label  || "2nd Grade"}</MenuItem>
                    <MenuItem value="3rd Grade">{languageData?.levelOptions[2].label  || "3rd Grade"}</MenuItem>
                    <MenuItem value="4th Grade">{languageData?.levelOptions[3].label  || "4th Grade"}</MenuItem>
                    <MenuItem value="5th Grade">{languageData?.levelOptions[4].label  || "5th Grade"}</MenuItem>
                    <MenuItem value="6th Grade">{languageData?.levelOptions[5].label  || "6th Grade"}</MenuItem>
                    <MenuItem value="7th Grade">{languageData?.levelOptions[6].label  || "7th Grade"}</MenuItem>
                    <MenuItem value="8th Grade">{languageData?.levelOptions[7].label  || "8th Grade"}</MenuItem>
                    <MenuItem value="9th Grade">{languageData?.levelOptions[8].label  || "9th Grade"}</MenuItem>
                    <MenuItem value="10th Grade">{languageData?.levelOptions[9].label  || "10th Grade"}</MenuItem>
                    <MenuItem value="11th Grade">{languageData?.levelOptions[10].label  || "11th Grade"}</MenuItem>
                    <MenuItem value="12th Grade">{languageData?.levelOptions[11].label  || "12th Grade"}</MenuItem>
                    <MenuItem value="Academic">{languageData?.levelOptions[12].label  || "Academic"}</MenuItem>
                </Select>
            </FormControl>
            <TextField
                fullWidth
                label={languageData?.onboarding?.details_input_step?.name_of_institution || "Name of institution"}
                autoFocus
                margin="normal"
                value={institutionName}
                onChange={(e) => setInstitutionName(e.target.value)}
            />
        </Box>
    );
};

export default DetailsInputStep;
