import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

const CircularProgressWithLabel = ({ percentage, label }) => {
    if (percentage > 100) percentage = 100
    if (percentage < 0) percentage = 0
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                variant="determinate"
                value={100}
                size={90}
                sx={{ color: '#e0e0e0', position: 'absolute', top: 0, left: 0, }}
            />
            <CircularProgress variant="determinate" value={percentage} size={90} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="body2" component="div" textAlign='center'>
                    {label}
                </Typography>
            </Box>
        </Box>
    );
};

export default CircularProgressWithLabel;