import React, { useState } from 'react';
import { Typography, Grid } from '@mui/material';
import { useLanguage } from '../../contexts/languageContext';
import OpenQuestion from './OpenQuestion';
import CustomLatex from '../../components/CustomLatex';

// todo: change color and align text 
const NestedQuestion = ({ questionId, title, nestedQuestions, onAnswer, savedAnswer, parentIndex }) => {
    const { isRTL, languageData } = useLanguage();
    //nested answer is already contain the questionId test so it doesnt need to be formatted before sending to the server.
    const [nestedAnswers, setNestedAnswers] = useState(savedAnswer || nestedQuestions.map((question) => ({ questionId: question._id, question: question.question, answer: "" })))

    const onNestedAnswer = (id, answer) => {
        setNestedAnswers(pervAnswers => {
            const index = pervAnswers.findIndex((ans) => ans.questionId === id)
            pervAnswers[index].answer = answer
            onAnswer(questionId, pervAnswers)
            return pervAnswers
        })

    }

    return (
        <div dir={isRTL ? 'rtl' : 'ltr'}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bolder' }}>
                <CustomLatex>{title}</CustomLatex>
            </Typography>
            <Grid container direction="column" mt={2}>
                {nestedQuestions.map((nestedQuestion, index) => {
                    const componentQuestion = { onAnswer: onNestedAnswer, title: nestedQuestion.question, questionId: nestedQuestion._id, savedAnswer: nestedAnswers[index].answer, ...nestedQuestion }
                    return <>
                        <Typography variant="h6">
                            {`${languageData?.quiz?.question_label} ${parentIndex + 1}.${index + 1}`}
                        </Typography>
                        <OpenQuestion key={index} {...componentQuestion} ></OpenQuestion>
                    </>
                })
                }
            </Grid>
        </div >
    );
};

export default NestedQuestion;
