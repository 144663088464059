import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const GradeProgressCircle = ({ gradePercentage }) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                variant="determinate"
                value={100}
                size={120}
                thickness={5}
                sx={{
                    color: '#e0e0e0',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
            />
            <CircularProgress
                variant="determinate"
                value={gradePercentage}
                color="success"
                size={120}
                thickness={5}
            />
            <Box
                position="absolute"
                top={0}
                left={0}
                bottom={0}
                right={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="h6" component="div" color="textSecondary">
                    {`${Math.round(gradePercentage)}%`}
                </Typography>
            </Box>
        </Box>
    );
};

export default GradeProgressCircle;
