import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CustomLatex from './CustomLatex';
import { useLanguage } from '../contexts/languageContext';
import { Divider } from '@mui/material';
import CircularProgressWithLabel from './CircularProgressWithLabel';

/**
 * Renders a AI indicator component.
 * @param {Object} props - The component props.
 * @param {string} props.label - The label for the Chip component.
 * @param {Object} props.data - The data object containing indicator and explanation.
 * @param {Array} props.data.indicator - The indicator
 * @param {number} props.data.questionGrade - question grade
 * @param {string} [props.backgroundColor="#E4D2FC"] - The background color of the ChipBox.
 * @param {string} [props.color="9C27B0"] - The color of the ChipBox.
 * @returns {JSX.Element|null} The rendered ChipBox component.
 */
const ChipBox = ({ label, data, backgroundColor = "#E4D2FC", color = "#9C27B0" }) => {
    const { languageData } = useLanguage()

    if (!label || !data || !data?.indicator || (Array.isArray(data?.indicator) && data?.indicator?.length === 0)) {
        return null;
    }
    const renderIndicatorBreakdown = ({ indicator: breakdown, questionGrade }) => {
        if (!Array.isArray(breakdown)) {
            return <Typography variant="subtitle1"><CustomLatex>{breakdown ?? ''}</CustomLatex></Typography>;
        }

        return breakdown.map((item, index) => {
            const allocated = parseFloat((questionGrade * (item.allocated_percentage / 100)).toFixed(1));
            const awarded = parseFloat((questionGrade * (item.awarded_percentage / 100)).toFixed(1));

            const awardedParts = awarded.toString().split('.');
            const decimalPlaces = awardedParts[1] ? awardedParts[1].length : 0;
            let awardedLabel;

            if (decimalPlaces >= 1) {
                awardedLabel = awarded.toFixed(1);
            } else {
                awardedLabel = awarded.toString();
            }

            return (
                <>
                    <Grid container key={index} sx={{ marginBottom: '8px', gap: '20px' }}>
                        <Grid item xs={12} md={5.2} lg={5.2} >
                            <Typography variant="subtitle1" >
                                <span style={{ fontWeight: "bold" }}>{languageData?.review?.response?.concept}</span>&nbsp;&nbsp;
                                <CustomLatex>{item.indicator}</CustomLatex>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={5.2} lg={5.2} >
                            <Typography variant="body2">
                                <span style={{ fontWeight: "bold" }}>{languageData?.review?.response?.explanation}</span>&nbsp;&nbsp;
                                <CustomLatex>{item.reason}</CustomLatex>
                            </Typography >
                        </Grid>
                        <Grid item xs={12} md={1} lg={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgressWithLabel
                                percentage={(awarded / allocated) * 100}
                                label={<>{awardedLabel}/{allocated} <br /> {languageData?.preview?.points_label}</>}
                            />
                        </Grid>
                    </Grid >
                    {index < breakdown?.length - 1 && <Divider sx={{ my: '10px' }} />}
                </>
            )
        });
    };

    return (
        <Grid container mt={2} spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
                <Box sx={{ border: '1px solid #ccc', borderRadius: '5px', padding: '8px' }}>
                    <Chip
                        icon={<AutoFixHighIcon color={color} sx={{ fontSize: '1rem' }} />}
                        label={label}
                        sx={{
                            backgroundColor: backgroundColor ?? '#E9DBFC',
                            color: color,
                            padding: '8px',
                            marginBottom: '8px',
                            '& .MuiChip-label': {
                                fontWeight: 'bold',
                                fontSize: '0.9rem',
                            },
                        }}
                    />
                    {renderIndicatorBreakdown(data)}
                </Box>
            </Grid>
        </Grid>
    );
};

export default ChipBox;