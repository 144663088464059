import React from 'react';
import { Grid, Box, Typography } from '@mui/material';

const HebrewLandingVideo = () => {
    return (
        <Grid container justifyContent="center" alignItems="center" >
            <Grid item xs={10} sm={10} md={8} lg={6}>
                <Box textAlign="center" >
                    <Typography variant="h4" gutterBottom>
                        גלו את כלי ה-AI החדשני שלנו
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        צפו בסרטון כדי לראות איך כלי ה-AI שלנו יכול לשפר את תהליך הבחינה עבורך
                    </Typography>
                </Box>

                <Box
                    sx={{
                        position: 'relative',
                        paddingBottom: '56.25%',
                        height: 0,
                        borderRadius: '8px',
                        overflow: 'hidden',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        my: 4
                    }}
                >
                    <iframe
                        src="https://www.youtube.com/embed/ZqZ_ohlRvgQ?si=s_QLszO1mc5Z9Eg5"
                        title="יצירה ובדיקת שאלות אוטומטית באמצעות AI"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    ></iframe>
                </Box>
            </Grid>
        </Grid>
    );
};

export default HebrewLandingVideo;
