import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { Business, Group, Insights, School, Work } from '@mui/icons-material';
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const OrganizationsUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <Business color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Enhance Your Organization's Training and Assessment with Cutting-Edge AI-Powered Solutions for Superior Performance">
                                    Enhance Your Organization's Training and Assessment with Cutting-Edge AI-Powered Solutions for Superior Performance
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Revolutionize your organization's training and assessment methodologies with our cutting-edge AI-powered tools, meticulously designed to transform every facet of employee development, training, and performance evaluation. Our state-of-the-art platform harnesses the power of advanced artificial intelligence to streamline and optimize the design, delivery, and assessment of training programs, providing you with unparalleled efficiency and effectiveness. Whether your objectives include enhancing team capabilities, performing comprehensive performance evaluations, or refining your recruitment processes, our AI-driven solutions offer the precision, innovation, and adaptability necessary to excel in today’s dynamic and competitive business environment.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Our comprehensive platform integrates a host of sophisticated features, including advanced analytics and automation capabilities, to empower you with the tools needed to make data-driven decisions. By leveraging our AI-powered tools, you can create highly customized training programs that address specific organizational needs, monitor employee progress with unparalleled accuracy, and gain actionable insights that drive continuous improvement. Discover the transformative impact of our AI-driven solutions on your organizational training and assessment practices, and unlock the full potential of your workforce to achieve strategic success and operational excellence.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12} md={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px', display: 'flex', flexDirection: 'column' }}>
                                    Why Choose Our AI-Driven Platform for Organizational Training and Assessments? Explore the Comprehensive Benefits
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Group color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Advanced Team Development and Skill Enhancement with Tailored Training Programs</Typography>
                                    </Box>
                                    Tap into the transformative capabilities of our AI-driven tools to develop and implement highly personalized assessments and training programs that address the distinct needs and skill gaps of your team. Our platform empowers you to create targeted training initiatives designed to elevate team competencies, knowledge, and overall performance. By aligning training strategies with your organization's strategic objectives, you ensure that your workforce is equipped to meet challenges head-on and contribute significantly to your organization’s success and growth.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Business color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Comprehensive Performance Evaluation and Metrics Tracking for Improved Organizational Efficiency</Typography>
                                    </Box>
                                    Utilize the advanced AI analytics capabilities to conduct thorough and insightful evaluations of employee performance through detailed exams and assessments. Our platform automates the feedback process, tracks a wide range of productivity metrics, and provides you with actionable insights that support informed decision-making. By leveraging these analytics, you can enhance organizational efficiency, ensure performance evaluations are precise and consistent, and foster continuous improvement within your team.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Insights color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">In-Depth Analytics and Strategic Insights to Optimize Training Outcomes</Typography>
                                    </Box>
                                    Gain invaluable and actionable insights into the effectiveness of your training programs and assessment strategies with our sophisticated analytics tools. Our platform delivers comprehensive reports and detailed data analysis, guiding strategic decision-making and optimizing training outcomes. By utilizing these insights, you can refine your training programs to achieve better results, support your organizational goals, and drive long-term success through data-informed strategies.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <School color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Effective Employee Training Programs to Promote Development and Growth</Typography>
                                    </Box>
                                    Create and implement highly effective training programs that support employee development and foster professional growth. Our platform enables you to design engaging and educational content tailored to meet your organizational objectives, ensuring that your training initiatives are impactful and aligned with your strategic goals. By investing in robust training programs, you contribute to the career development of your employees and support the long-term success and competitive advantage of your organization.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Work color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Optimized Recruitment Processes for Identifying and Selecting Top Talent</Typography>
                                    </Box>
                                    Streamline and enhance your recruitment processes with our automated assessment tools designed to efficiently identify and evaluate top candidates. Our platform simplifies the candidate evaluation process, ensuring that you select the most suitable individuals for your organization while improving the overall efficiency of your hiring process. By optimizing your recruitment strategies, you can attract and retain high-quality talent that drives organizational success and growth.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                How to Fully Utilize Our AI-Powered Platform for Organizational Training and Assessments to Achieve Optimal Results
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Maximize the transformative benefits of our AI-powered platform to revolutionize your approach to organizational training and assessments. Our extensive suite of tools allows you to design and manage exams with precision, automate comprehensive performance evaluations, and leverage advanced analytics for actionable insights. By adopting our technology, you can redefine your employee development and performance management strategies, ensuring that your organization remains at the forefront of innovation and competitiveness. Embrace the future of training and assessment with our intelligent solutions, and drive your organization toward unparalleled success and excellence.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default OrganizationsUseCase;
