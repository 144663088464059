import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { School, Group, Comment, Feedback } from '@mui/icons-material';
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const ClassInteractionsUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <School color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Enhance Class Interactions">
                                    Enhance Class Interactions with AI-Powered Tools - Coming Soon
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                In today's fast-paced educational landscape, it's more important than ever to keep students engaged and motivated. That's why we're excited to introduce our AI-powered platform designed to revolutionize classroom interactions. Our cutting-edge tools are specifically engineered to enhance the learning experience by fostering better communication, collaboration, and engagement among students and educators alike. Imagine a classroom where AI helps manage the flow of information, provides real-time feedback, and supports a more interactive learning environment. Our platform is not just a tool; it's a comprehensive solution that empowers educators to take their classroom management to the next level.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Our AI-driven platform is designed with the modern classroom in mind. Whether you're dealing with large groups or small classes, our tools can adapt to various educational settings, making it easier to manage tests, assignments, and assessments. By integrating advanced AI features, we enable educators to streamline classroom management tasks, allowing more time to focus on what truly matters—teaching and student interaction. This platform isn't just about making things easier; it's about creating an environment where students are more engaged, feel supported, and can thrive academically.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                The platform's intuitive design allows for seamless integration into your existing workflow, providing you with a powerful set of tools that work hand-in-hand with your current educational strategies. From tracking student progress to facilitating real-time discussions, our AI-powered platform offers a versatile solution that meets the needs of today's educators. With our tools, you can easily create dynamic and interactive classroom experiences that foster collaboration and improve learning outcomes. Whether you're looking to enhance student participation or streamline grading and feedback processes, our platform is here to help you achieve your goals.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ mb: 2, fontSize: '35px' }}>
                                    Why Choose Our Platform for Interactive Class Management?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Group color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">AI-Enhanced Collaborative Learning</Typography>
                                    </Box>
                                    Collaborative learning is a key component of effective education, and our platform is designed to enhance this experience. Our AI tools facilitate interactions among students, encouraging them to work together on projects, share ideas, and engage in meaningful discussions. This collaborative approach not only boosts engagement but also helps students develop critical thinking and teamwork skills. With our platform, educators can create a more inclusive and interactive classroom environment where every student has the opportunity to participate and contribute.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Comment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Interactive Discussions and Real-Time Feedback</Typography>
                                    </Box>
                                    In a traditional classroom setting, it's often challenging to keep students engaged in discussions and ensure that everyone receives timely feedback. Our platform addresses these challenges by providing AI-powered tools that facilitate interactive discussions and offer real-time feedback. Educators can use these tools to conduct live polls, initiate group discussions, and provide instant feedback on student work. This immediate interaction not only keeps students engaged but also helps them understand their performance in real-time, allowing for quicker adjustments and continuous improvement.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Feedback color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Instant AI-Driven Feedback for Continuous Improvement</Typography>
                                    </Box>
                                    Feedback is an essential part of the learning process, and with our AI-driven feedback system, educators can provide students with instant, actionable insights into their performance. This system is designed to identify areas where students may need additional support and offer suggestions for improvement. By providing real-time feedback, our platform helps students understand their strengths and weaknesses, enabling them to make continuous progress. This feature is especially valuable in helping students stay on track and achieve their academic goals.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ mb: 2, fontSize: '35px' }}>
                                How to Leverage Our AI Tools for Effective Class Interactions
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Leveraging our AI-powered platform is easy and intuitive, allowing educators to create engaging and interactive class experiences with minimal effort. Our tools are designed to help you manage tests, assignments, and discussions more effectively, providing you with the resources you need to enhance student participation and streamline administrative tasks. By integrating these features into your classroom, you can create a more dynamic and engaging learning environment that supports student success. Whether you're looking to increase student engagement, improve learning outcomes, or simply make classroom management more efficient, our platform provides the tools and support you need to succeed.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default ClassInteractionsUseCase;
