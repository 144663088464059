import React, { useState, useEffect } from 'react';
import BlogView from './blog/view/blog-view';
import { requestBlogPosts } from '../../requests/blog';
import { Helmet } from 'react-helmet-async';

export default function BlogPage() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await requestBlogPosts();
                setPosts(response);
            } catch (error) {
                console.error('Error fetching blog posts:', error);
            }
        };

        fetchPosts();
    }, []);

    return <>
        <Helmet>
            <title>Exam Generator | Blog</title>
            <meta name="description" content="Stay updated with the latest news and insights on exam generation and AI in education. Our blog covers tips, trends, and best practices for creating personalized, high-quality exams." />
        </Helmet>
        <BlogView posts={posts} />;
    </>
}
