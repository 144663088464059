import React from 'react';
import './HighlightButton.css'; // Import the CSS file

const HighlightButton = ({ children }) => {

    return (
        <div className="highlight-icon-button">
            {children}
        </div>
    );
};

export default HighlightButton;
