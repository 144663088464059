import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
// Gifs
import quizGif from './assets/gifs/score.gif';
import examGif from './assets/gifs/ab-testing.gif';
import homeworkGif from './assets/gifs/question.gif';
import competitionGif from './assets/gifs/choosing-customer.gif';
import formativeAssessmentGif from './assets/gifs/classroom.gif';
import { useNavigate } from 'react-router-dom';

const educationData = [
  { image: examGif, title: 'מבחנים', subtitle: 'בחנו כישורים וידע בסביבה מקצועית וללא הסחות דעת' },
  { image: quizGif, title: 'משימות', subtitle: 'העסיקו את התלמידים מרחוק בצורה יעילה ומהנה' },
  { image: homeworkGif, title: 'שיעורי בית', subtitle: 'העניקו משימות לאחר שעות הלימודים בצורה ידידותית ואינטראקטיבית' },
  { image: competitionGif, title: 'אינטראקציות בכיתה', subtitle: 'העצימו את התלמידים, אתגרו אותם ועודדו דיונים כדי לחוות תוצאות מדהימות' },
  { image: formativeAssessmentGif, title: 'הערכה מעצבת', subtitle: 'מוטיבציה חיובית, משוב מעודד והזדמנויות שוות עם גישה חינוכית חדשה' },
];

const HebrewEducationCards = () => {
  const navigate = useNavigate();

  const moveToSignup = () => {
    navigate('/signup');
  };

  const renderCards = (data) => (
    data.map((item, index) => (
      <Grid p={4} key={index} item xs={12} sm={6} md={4}>
        <Card onClick={moveToSignup} sx={{ cursor: 'pointer', height: '100%', display: 'flex', flexDirection: 'column', boxShadow: 2, '&:hover': { boxShadow: 5 } }}>
          <img src={item.image} alt={item.title} style={{ padding: 4, width: '100px', height: 'auto', borderRadius: '8px 8px 0 0' }} />
          <CardContent style={{ flex: 1 }}>
            <Typography variant="h5" component="h2" style={{ fontWeight: '600', marginBottom: '1rem' }}>
              {item.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {item.subtitle}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))
  );

  return (
    <div id='features' style={{ textAlign: 'left', marginTop: '100px' }}>
      <Typography variant="h3" align="center" sx={{ mb: 4 }}>
        התכנים שלנו
      </Typography>
      <Grid container spacing={3} justifyContent="center" sx={{ p: 3 }}>
        {renderCards(educationData)}
      </Grid>
    </div>
  );
}

export default HebrewEducationCards;
