import React, { useCallback } from 'react';
import { Tooltip, AppBar, Toolbar, IconButton, Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SettingsButton from '../../components/Settings/SettingsButton';
import ShareButton from '../../components/Share/ShareButton';
import { useLanguage } from '../../contexts/languageContext';
import ChooseLanguageBtn from '../../components/ChooseLanguageBtn';
import HighlightButton from '../../components/HighlightButton/HighlightButton';
import { useLobby } from '../../contexts/LobbyContext';


const PreviewHeader = ({ openExport, examId, handleSaveSettings, examSettings }) => {
    const { isRTL, languageData } = useLanguage();
    const { lobbyStatus } = useLobby();

    const handleViewExam = () => {
        window.open(`/quiz?id=${examId}`, '_blank');
    };

    const handleOpenExport = () => {
        openExport();
    };

    const viewExamButton = useCallback(() => {
        // to avoid undefined
        const title = (lobbyStatus?.auto_grade == false ? languageData?.preview?.tooltips?.start_auto_grade : languageData?.preview?.tooltips?.view_exam) ?? "Preview your exam";
        return lobbyStatus?.auto_grade == false ? (
            <HighlightButton>
                <Tooltip title={title} placement="bottom">
                    <IconButton onClick={handleViewExam}>
                        <PlayArrowIcon />
                    </IconButton>
                </Tooltip>
            </HighlightButton>
        ) : (
            <Tooltip title={title} placement="bottom">
                <IconButton onClick={handleViewExam}>
                    <PlayArrowIcon />
                </IconButton>
            </Tooltip>
        )
    }, [lobbyStatus]);

    return (
        <AppBar elevation={1} position="sticky" sx={{ bgcolor: 'white' }}>
            <Toolbar sx={{ justifyContent: 'flex-end' }}>
                <Tooltip title={languageData.preview.tooltips.settings} placement="bottom">
                    <span>
                        <SettingsButton onSubmit={handleSaveSettings} examSettings={examSettings} />
                    </span>
                </Tooltip>

                {viewExamButton()}
                <Tooltip title={languageData.preview.tooltips.export_exam} placement="bottom">
                    <Button variant="contained" color="secondary" sx={{ mx: 1 }} onClick={handleOpenExport}>
                        {languageData.preview?.export_exam}
                    </Button>
                </Tooltip>
                <Tooltip title={languageData.preview.tooltips.share_exam} placement="bottom">
                    <span>
                        <ShareButton forceDesktopStyle isRTL={isRTL} languageData={languageData?.preview} examId={examId} />
                    </span>
                </Tooltip>
                <ChooseLanguageBtn />
            </Toolbar>
        </AppBar>
    );
};

export default PreviewHeader;
