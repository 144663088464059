import React, { useState, useContext, createContext, useEffect } from 'react';
import { Typography, Backdrop, Box } from '@mui/material';
import generatingExamGif from '../assets/loader2.gif';
import readFileGif from '../assets/loader3.gif';
import focusGif from '../assets/focus.gif';
import LoaderProgressBar from '../components/LoaderProgressBar';
import { defaultParams } from '../utils/examOptions';
import { useLanguage } from '../contexts/languageContext';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

const LoaderContext = createContext();

export const useLoader = () => {
    const context = useContext(LoaderContext);
    if (!context) {
        throw new Error('useLoader must be used within a LoaderProvider');
    }
    return context;
};

export const loaderTypes = {
    READING_FILE: 'READING_FILE',
    DEFAULT: 'DEFAULT',
    FOCUS: 'FOCUS',
    GENERATE: 'GENERATE',
    ONBOARDING: 'ONBOARDING',
};

const loaders = {
    [loaderTypes.READING_FILE]: readFileGif,
    [loaderTypes.DEFAULT]: generatingExamGif,
    [loaderTypes.FOCUS]: focusGif,
    [loaderTypes.GENERATE]: generatingExamGif,
    [loaderTypes.ONBOARDING]: readFileGif,
};

const waitingTips = [
    "Sometimes trial and error is necessary to achieve a quality result.",
    "Invest in quality focus for better results.",
    "The larger the content, the higher the chances of a quality exam.",
    "Provide a sample question to help the model understand the level and structure.",
    "A supervised exam can be set under the settings icon on the next screen.",
    "Stay positive and believe in yourself!",
    "Consider using a variety of question types to assess different aspects of knowledge.",
];

const hebrewWaitingTips = [
    "לפעמים נדרש ניסוי וטעייה בכדי להגיע לתוצאה איכותית",
    "השקעה במיקוד איכותי מובילה לתוצאות טובות יותר",
    "ככל שהתוכן גדול יותר כך הסיכוי למבחן איכותי גדל",
    "ספקו שאלה לדוגמא כדי שהמודל יוכל להבין רמה ומבנה",
    "ניתן להגדיר מבחן מפוקח תחת אייקון ההגדרות במסך הבא",
    "הישארו חיוביים והאמינו בעצמכם",
    "שקלו להשתמש במגוון סוגי שאלות כדי להעריך היבטים שונים של ידע",
];

const heFocusText = "מייצרים מיקוד עבור המבחן";
const focusText = "Generating study areas..."
const heUplaodOnboardingDataText = "מעלים נתונים...";
const uploadOnboardingDataText = "Uploading onboarding data...";


export const LoaderProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [loaderSrc, setLoaderSrc] = useState(generatingExamGif);
    const [type, setType] = useState(loaderTypes.DEFAULT);
    const [questions, setQuestions] = useState(defaultParams.questions);
    const { language } = useLanguage();
    const tips = language === 'he' ? hebrewWaitingTips : waitingTips;
    const [displayedTip, setDisplayedTip] = useState('');

    useEffect(() => {
        if (loading && type === loaderTypes.GENERATE) {
            let tipIndex = Math.floor(Math.random() * tips.length); // randomize the starting tip
            setDisplayedTip(tips[tipIndex]); // start with the random tip

            const interval = setInterval(() => {
                const relativeTipIndex = tipIndex % tips.length;
                setDisplayedTip(tips[relativeTipIndex]);
                // typeText(tips[newIndex]);
                tipIndex += 1;
            }, 10000); // Change to match the tip duration

            return () => clearInterval(interval); // Cleanup interval on unmount or type change
        }
    }, [loading, type, language]);


    const typeText = (text) => {
        setDisplayedTip(''); // Clear previous text
        let index = 0;
        const interval = setInterval(() => {
            setDisplayedTip(prev => prev + text.charAt(index));
            index += 1;
            if (index >= text.length) {
                clearInterval(interval);
            }
        }, 60); // Adjust typing speed here
    };

    const openLoader = (type = loaderTypes.DEFAULT, newQuestions = defaultParams.questions) => {
        setLoading(true);
        setType(type);
        setLoaderSrc(loaders[type] || generatingExamGif);
        if (newQuestions) setQuestions(newQuestions);
    };

    const closeLoader = () => {
        setLoading(false);
        setDisplayedTip(''); // Clear displayed tip on close
    };

    return (
        <LoaderContext.Provider value={{ loading, openLoader, closeLoader }}>
            {children}
            <Backdrop open={loading} style={{ zIndex: 9999, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                <Box style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '50%', padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={loaderSrc} alt="Loading" style={{ width: '100px', height: 'auto' }} />
                </Box>
                {loading && <LoaderProgressBar questions={questions} />}
                {(type === loaderTypes.GENERATE || type === loaderTypes.FOCUS) &&
                    <Typography
                        variant="body1"
                        color="#2E323A"
                        gutterBottom
                        style={{
                            fontWeight: 'bold',
                            backgroundColor: '#F5FBFF',
                            padding: '10px',
                            margin: '10px',
                            textAlign: 'center',
                            borderRadius: '5px',
                            maxWidth: '300px'
                        }}
                    >
                        <TipsAndUpdatesIcon style={{ margin: '5px', fontSize: '1rem', color: '#F6BF26' }} />
                        {type === loaderTypes.FOCUS
                            ? (language === 'he' ? heFocusText : focusText)
                            : displayedTip}
                        {type === loaderTypes.ONBOARDING ? (language === 'he' ? heUplaodOnboardingDataText : uploadOnboardingDataText) : ''}
                    </Typography>
                }
            </Backdrop>
        </LoaderContext.Provider>
    );
};
