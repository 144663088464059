import { IconButton, Tooltip } from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import { useLanguage } from "../../contexts/languageContext";
import useSnackBar from "../../hooks/useSnackBar";
import { SnackbarTypes } from "../../utils/snackbarTypes";

export function UploadImageButton({ questionId, imageInputId, handleUploadImage }) {
    const { languageData } = useLanguage();
    const { openSnackBar } = useSnackBar();

    const handleInputChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            handleUploadImage(questionId, e);
        } else {
            openSnackBar(SnackbarTypes.FILE_TYPE_NOT_SUPPORTED.field)
        }
    }
    return (
        <>
            <Tooltip title={languageData?.preview?.add_image ?? "Add Image"} arrow>
                <IconButton>
                    <label htmlFor={imageInputId} style={{ cursor: 'pointer' }}>
                        <ImageIcon />
                    </label>
                </IconButton>
            </Tooltip>
            <input id={imageInputId} type="file" accept="image/*" style={{ display: 'none' }} onChange={handleInputChange} />
        </>
    )
}