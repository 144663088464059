import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { Assignment, Lightbulb, LocalCafe, Tune } from '@mui/icons-material';
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const AIQuiz = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4}>
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <Assignment color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography
                                    variant="h3"
                                    sx={{ flexGrow: 1, fontSize: '35px' }}
                                    component="h1"
                                    aria-label="AI-Powered Question Generator for Quizzes and Exams"
                                >
                                    Revolutionize Your Assessment Creation with Our AI-Powered Question Generator
                                </Typography>
                            </Box>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mb: 4, lineHeight: 1.6 }}
                            >
                                Welcome to a new era in education, where the process of creating quizzes, tests, and exams is transformed by our cutting-edge AI-powered question generator. Designed specifically to meet the needs of modern educators, our platform uses state-of-the-art artificial intelligence technology to streamline and enhance the entire assessment creation process. By leveraging the power of AI, you can now generate high-quality, relevant questions in a fraction of the time it would take to create them manually. Whether you are an educator in a K-12 setting, a university professor, or a corporate trainer, our AI-powered platform is designed to cater to your unique needs, providing you with an efficient, effective, and innovative solution to your assessment challenges.
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mb: 4, lineHeight: 1.6 }}
                            >
                                Our AI question generator isn't just about saving time—it’s about ensuring the quality and relevance of your assessments. By automating the question generation process, you can focus on more critical aspects of your teaching and training, such as engaging with students and analyzing learning outcomes. Our platform is capable of generating a wide variety of question types, from multiple-choice questions to short-answer questions, essay prompts, and more, all tailored to the specific content and difficulty level you require. With our AI-driven approach, you can create assessments that truly reflect your educational goals and objectives, ensuring that each quiz, test, or exam you create is both challenging and fair.
                            </Typography>
                        </Grid>

                        {/* Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography
                                    variant="h3"
                                    component="h2"
                                    sx={{ mb: 2, fontSize: '35px' }}
                                >
                                    Unparalleled Benefits of Using Our AI-Powered Question Generator
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Lightbulb color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">
                                            Generate Innovative and Diverse Question Ideas
                                        </Typography>
                                    </Box>
                                    Our AI-powered platform excels at generating creative and innovative question ideas that are specifically tailored to your content. This feature is particularly valuable for educators who need to create engaging and thought-provoking questions that go beyond the typical rote memorization. Whether you are looking to assess higher-order thinking skills or simply need a fresh perspective on your material, our AI generator can provide you with a diverse range of question ideas that challenge your students and promote deeper learning.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <LocalCafe color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">
                                            Boost Efficiency, Save Time, and Reduce Workload
                                        </Typography>
                                    </Box>
                                    One of the greatest advantages of our AI-powered question generator is the significant amount of time it saves educators and trainers. By automating the most time-consuming aspects of assessment creation, our platform allows you to quickly generate large quantities of high-quality questions with just a few clicks. This efficiency not only saves you time but also reduces your overall workload, freeing you up to focus on more important tasks, such as providing personalized feedback, engaging with students, and refining your teaching strategies. Our AI generator is designed to be user-friendly and intuitive, making it easy for educators of all tech-savviness levels to use.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Tune color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">
                                            Create Multiple Variants of Questions for Comprehensive Assessment
                                        </Typography>
                                    </Box>
                                    Our AI technology is not limited to generating just one version of a question. It allows you to create multiple variants of the same question, ensuring that your assessments are comprehensive and cover all necessary aspects of the content. This feature is particularly useful for creating different versions of the same test or quiz, or for providing students with varied question formats that test their knowledge from different angles. Whether you are assessing a broad topic or diving deep into specific details, our AI generator can provide you with the versatility you need to create effective and diverse assessments.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Typography variant="h6">
                                            Enhance the Quality and Relevance of Your Assessments
                                        </Typography>
                                    </Box>
                                    In today’s fast-paced educational environment, the quality and relevance of assessments are more important than ever. Our AI-powered question generator is designed to ensure that each question it produces is not only relevant to the content but also aligned with your specific educational objectives. Whether you are teaching a complex subject or a more straightforward topic, our AI generator can help you create questions that are accurate, fair, and aligned with the learning goals you have set for your students. By using our platform, you can be confident that your assessments will be both high-quality and highly relevant, providing your students with the best possible learning experience.
                                </Typography>
                            </Box>

                            <Typography
                                variant="h3"
                                component="h2"
                                sx={{ mb: 2, fontSize: '35px' }}
                            >
                                How to Maximize the Benefits of AI-Generated Questions
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mt: 2, mb: 4, lineHeight: 1.6 }}
                            >
                                Integrating our AI-powered question generator into your assessment development workflow can have a transformative impact on the way you create quizzes, tests, and exams. To maximize the benefits, start by identifying the specific types of questions you need for your assessments. Whether you are looking for multiple-choice questions, true/false questions, or essay prompts, our platform can generate a wide variety of question types that meet your specific needs. Next, take advantage of our platform's ability to tailor questions to different difficulty levels. This feature allows you to create assessments that are appropriately challenging for your students, ensuring that they are both fair and effective.
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mt: 2, mb: 4, lineHeight: 1.6 }}
                            >
                                Another way to maximize the benefits of our AI-powered question generator is to use it as a tool for continuous improvement. By analyzing the questions generated by our platform and comparing them to student performance data, you can identify areas where your assessments could be improved. This iterative process allows you to continually refine your assessments, making them more effective over time. Additionally, our platform's ability to generate multiple variants of questions makes it easy to create different versions of the same assessment, providing you with the flexibility to adapt your teaching and testing strategies as needed.
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mt: 2, mb: 4, lineHeight: 1.6 }}
                            >
                                Finally, don't forget to leverage the time-saving benefits of our AI-powered platform. By automating the question generation process, you can free up more time to focus on other important aspects of your teaching or training. Whether you use that time to provide personalized feedback to your students, develop new teaching materials, or simply take a well-deserved break, our AI generator can help you work more efficiently and effectively. With our platform, you can achieve a perfect balance between quality and efficiency, creating assessments that are both high-quality and time-effective.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                href="/sign-up"
                                aria-label="Sign Up to Use AI-Powered Question Generator"
                            >
                                Sign Up Now
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
}

export default AIQuiz;
