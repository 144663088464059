import React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { useLanguage } from '../contexts/languageContext'

export default function FullWidthTextField({ textFieldProps, value, onChange, limit, placeholder }) {
  const { languageData } = useLanguage()

  return (
    <Box
      sx={{
        minWidth: '300px',
        margin: 'auto',
        padding: '20px',
      }}
    >
      <TextField
        value={value}
        fullWidth
        label={languageData.text_input_placeholder}
        id='fullWidth'
        inputProps={{ maxLength: parseInt(limit) }}
        multiline
        // autoFocus // no need 
        onChange={onChange}
        placeholder={placeholder ?? 'Input Example: The attack on Pearl Harbor on December 7, 1941, was a pivotal moment that thrust the United States into World War II. Tensions between Japan and the U.S. had been escalating, leading to Japans plan to strike the U.S. Pacific Fleet. In a surprise attack, Japanese naval forces launched an assault on Pearl Harbor, causing significant damage to the American fleet. The attack resulted in the sinking of battleships like the USS Arizona and led to the loss of thousands of lives. The U.S. responded by declaring war on Japan and joining the Allied forces. The attack marked a turning point in the war and transformed the United States into a global superpower. Its long-term consequences reshaped international relations and led to the internment of Japanese Americans. The attack on Pearl Harbor remains a significant event in history, serving as a reminder of the need for preparedness and the impact of decisive actions.'}
        {...textFieldProps}
      />
    </Box>
  )
}
