import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { Assessment, AssignmentTurnedIn, Chat } from '@mui/icons-material';
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const SkillsKnowledgeAssessment = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4}>
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <Assessment color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: 35 }} component="h1" aria-label="Advanced Skills and Knowledge Assessment Tools for Enhanced Learning Outcomes">
                                    Advanced Skills and Knowledge Assessment Tools: Optimizing Learning and Evaluation
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                Elevate your educational assessment strategies with our advanced skills and knowledge assessment tools, meticulously designed to optimize and streamline the evaluation process. Our state-of-the-art solutions provide deep insights into learning outcomes, automate grading procedures, and offer actionable feedback to enhance the overall educational experience. Ideal for educators, institutions, and organizations seeking to improve the efficiency, accuracy, and effectiveness of their assessment processes, our tools are engineered to deliver precise and comprehensive evaluation results.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                Our platform offers a robust suite of features that allows educators to conduct thorough assessments, gain valuable insights into student performance, and drive improvements in teaching methodologies. By leveraging automation, detailed analytics, and real-time feedback, educators can ensure that assessments are both meaningful and impactful, fostering better learning outcomes and supporting continuous improvement in educational practices.
                            </Typography>
                        </Grid>
                        {/* Benefits Section */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ mb: 2, fontSize: 33 }}>
                                    Key Benefits of Our Comprehensive Skills and Knowledge Assessment Solutions
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 2, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Assessment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">In-Depth Assessment Capabilities with Advanced Analytics</Typography>
                                    </Box>
                                    Conduct detailed skills and knowledge assessments with our platform's advanced analytics capabilities. Our tools provide rich, actionable insights that help identify learning gaps and strengths, enabling educators to implement targeted improvements and achieve better educational outcomes. The comprehensive data analysis facilitates a deeper understanding of student performance and supports the development of more effective teaching strategies.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 2, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <AssignmentTurnedIn color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Efficient Automated Grading for Consistency and Accuracy</Typography>
                                    </Box>
                                    Simplify and enhance your grading process with our automated tools that ensure accurate, consistent, and timely feedback. By automating grading, you can save valuable time, reduce the risk of human error, and focus more on enhancing the learning experience. Our system's automated evaluation capabilities support a streamlined approach to grading, contributing to a more efficient and effective assessment process.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Chat color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Actionable Feedback and Advanced Reporting for Better Learning Insights</Typography>
                                    </Box>
                                    Provide meaningful feedback and monitor student performance with our advanced reporting and analytics features. Gain insights into student progress, track assessment results, and identify areas for improvement to tailor your teaching strategies effectively. Our platform's advanced feedback mechanisms ensure that educators can deliver constructive feedback that drives student success and supports continuous learning and development.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant="h3" component="h2" sx={{ mb: 2, fontSize: 33 }}>
                                    How to Effectively Utilize Our Assessment Tools for Enhanced Learning and Evaluation
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                    Maximize the potential of our advanced assessment tools to create customized assessments, receive instant feedback, and efficiently track results. Integrate our platform into your educational strategies to benefit from precise, actionable data that drives improved learning outcomes. By utilizing our solutions, educators and institutions can enhance the effectiveness of their assessments, streamline grading processes, and achieve higher standards of educational excellence.
                                </Typography>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default SkillsKnowledgeAssessment;
