import React from 'react';
import { Stack } from '@mui/material';
// Components
import HebrewLandingHeader from './HebrewLandingHeader';
import HebrewWelcomeArea from './HebrewWelcomeArea';
import HebrewFeaturesSmall from './HebrewFeaturesSmall';
import HebrewCounterParallax from './HebrewCounterParallax';
import HebrewFooter from './HebrewFooter';
import HebrewFAQ from './HebrewFAQ';
import HebrewEducationCards from './HebrewEducationCards';
import { colors } from '../../utils/customTheme';
import { isMobile } from '../../utils';
import HebrewLandingVideo from './HebrewLandingVideo';

const HebrewLandingPage = () => {
    return (
        <div dir='rtl' style={{ backgroundColor: colors.onboarding }} >
            <HebrewLandingHeader />
            <Stack spacing={isMobile ? 0 : 1}>
                <HebrewWelcomeArea />
                <HebrewLandingVideo />
                <HebrewFeaturesSmall />
                <HebrewEducationCards />
                <HebrewCounterParallax />
                <HebrewFAQ />
                <HebrewFooter />
            </Stack>
        </div>
    );
};

export default HebrewLandingPage;
