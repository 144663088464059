import React from 'react';
import Latex from 'react-latex-next';

const CustomLatex = ({ children, ...props }) => {
    const delimiters = [{ left: '$$', right: '$$', display: false }];
    if (!children) return null;
    return <Latex delimiters={delimiters} {...props}>{children}</Latex>;
};

export default CustomLatex;
