import PropTypes from 'prop-types';

// material-ui
import { Card, Box, Chip, Grid, Stack, Typography } from '@mui/material';

// material-ui icons
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

/**
 * @param {{color: string, title: string, count: number, percentage: number, isLoss: boolean, extra: number, lossReversed: boolean, countProps: Object, newExtra: string}} param0 
 * @returns 
 */
const AnalyticNumeric = ({ color = 'success', title, count, percentage, isLoss, extra, lossReversed, countProps = {}, newExtra = '' }) => (
    <Card sx={{ p: 2 }}>
        <Stack spacing={0.5}>
            <Typography variant="body2" color="textSecondary">
                {title}
            </Typography>
            <Grid container alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="inherit" {...countProps}>
                        {count}
                    </Typography>
                </Grid>
                {percentage && (
                    <Grid item>
                        <Chip
                            variant="combined"
                            color={lossReversed ? (isLoss ? 'success' : 'warning') : (isLoss ? 'warning' : 'success')}
                            icon={
                                <>
                                    {!isLoss && <TrendingUpIcon style={{ fontSize: '0.75rem', color: 'inherit' }} />}
                                    {isLoss && <TrendingDownIcon style={{ fontSize: '0.75rem', color: 'inherit' }} />}
                                </>
                            }
                            label={`${isLoss ? 100 - percentage : percentage}%`}
                            sx={{ ml: 1.25, pl: 1 }}
                            size="small"
                        />
                    </Grid>
                )}
            </Grid>
        </Stack>
        <Box sx={{ pt: 1.5 }}>
            {extra && <Typography variant="caption" color="textSecondary">
                extra {' '}
                <Typography component="span" variant="caption" sx={{ color: `${color || 'primary'}.main` }}>
                    {extra}
                </Typography>{' '}
                today
            </Typography>}
            {!extra && newExtra && <Typography variant="caption" color="textSecondary">{newExtra}</Typography>}
        </Box>
    </Card >
);

AnalyticNumeric.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    count: PropTypes.string,
    percentage: PropTypes.number,
    isLoss: PropTypes.bool,
    lossReversed: PropTypes.bool,
    extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default AnalyticNumeric;