import React from 'react';
import { AppBar, Toolbar, Link as MuiLink, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ExamLogo from '../../assets/Icon.png';
import { Link } from 'react-router-dom';
import { isMobile } from '../../utils';
import HoverLinkHeader from './HoverLinkHeader';
import ChooseLanguageBtn from '../../components/ChooseLanguageBtn';

const gradientButton = {
    background: 'linear-gradient(45deg, #1976D2 30%, #2F5B89 90%)',
    borderRadius: 3,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    border: 0,
    color: 'white',
}

const LandingHeader = () => {
    const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate('/login');
    };

    const handleSignUpClick = () => {
        navigate('/signup');
    };

    const handleFAQClick = () => {
        navigate('/#faq');
    };

    return (
        <AppBar sx={{ backgroundColor: 'white', color: 'black' }} elevation={0}>
            <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <MuiLink href="/#welcome" color="inherit" aria-label="Go to Homepage">
                        <img src={ExamLogo} alt="Exam Logo" width={30} height={30} />
                    </MuiLink>
                    {!isMobile && (
                        <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', marginLeft: 2 }}>
                            <HoverLinkHeader label="Product" />
                            <HoverLinkHeader label="Who's it for" />
                            <HoverLinkHeader label="Use Cases" />

                            {/* Remaining links */}
                            <MuiLink onClick={handleFAQClick} href="#faq" color="inherit" underline="none">
                                FAQ
                            </MuiLink>
                            <MuiLink href="#footer" color="inherit" underline="none">
                                Contact Us
                            </MuiLink>
                        </Box>
                    )}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button onClick={handleLoginClick} color="inherit" variant="outlined" sx={{ marginRight: 2 }}>
                        Log in
                    </Button>
                    <Button variant='contained' color='primary' endIcon={<PlayArrowIcon />} onClick={handleSignUpClick} sx={gradientButton} >
                        Sign Up
                    </Button>
                    <ChooseLanguageBtn />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default LandingHeader;
