import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SettingsIcon from '@mui/icons-material/Settings';
import CreateIcon from '@mui/icons-material/Create';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { isMobile } from '../../utils';
import { colors } from '../../utils/customTheme';

const steps = [
    { title: 'Upload Content', icon: <UploadFileIcon />, details: ['Files & Presentations', 'Images & Videos', 'Free text', 'Past exams'] },
    { title: 'Setup', icon: <SettingsIcon />, details: ['Personalization', 'Questions types', 'Study Area', 'Skills to test'] },
    { title: 'Create Exam', icon: <CreateIcon />, details: ['Modify', 'Collaborate', 'Adjust Settings'] },
    { title: 'Take Exam ', icon: <AutoStoriesIcon />, details: ['Auto-Grade', 'Supervision', 'Open ended', 'Responsive'] },
    { title: 'Conclusions Dashboard', icon: <DashboardIcon />, details: ['Common mistakes', 'AI personal report', 'Instant feedback', 'Easy sharing'] },
];

const WorkflowStep = ({ title, icon }) => (
    <Grid item xs={steps.length / 3} sm={steps.length / 3} md={steps.length / 3}>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
            {React.cloneElement(icon, { fontSize: 'medium', sx: { color: colors.onboarding } })}
            <Typography variant="h6" sx={{ mt: 2, mb: 1, color: 'white' }}>{title}</Typography>
        </Box>
    </Grid>
);

const WorkflowComponent = () => {
    return (
        <Box mt={10} sx={{ flexGrow: 1, p: 3, backgroundColor: '#131921' }}>
            <Typography variant="h4" align="center" gutterBottom sx={{ p: 3, color: 'white' }}>
                How StudyWise Works
            </Typography>
            <Grid container spacing={isMobile ? 2 : 4} justifyContent="space-around" alignItems="center">
                {steps.map((step, index) => (
                    <React.Fragment key={index}>
                        <WorkflowStep {...step} />
                        {index < steps.length - 1 && (
                            <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Box sx={{ width: '50px', height: '2px', backgroundColor: 'white' }} />
                            </Grid>
                        )}
                    </React.Fragment>
                ))}
            </Grid>
        </Box>
    );
};

export default WorkflowComponent;
