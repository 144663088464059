import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// MUI
import { Grid, Container, Box, Typography } from '@mui/material';
// Components
import Teachers from './Teachers';
import DashboardChart, { chartTypes } from './DashboardChart';
import { useManagerDashboard } from '../../contexts/ManagerDashboardContext';
import Header from '../../components/Header';
import { useUser } from '../../hooks/useUser';
import ROLES from '../../utils/roles';

const ManagerDashboard = () => {
    const { user } = useUser();
    if (!user || !user?.org_id || !user?.roles?.some(role => role === ROLES.MANAGER.label))
        return null;

    const { org_id } = useParams();
    const { fetchExamTaken, fetchResponsesCount, fetchTokensCount, fetchOrg, managerDashboardData } = useManagerDashboard();

    useEffect(() => {
        if (org_id && !managerDashboardData?.org) {
            fetchOrg(org_id);
        }
    }, [org_id]);

    return (
        <>
            <Header />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                        {managerDashboardData?.org?.logo && <img src={`data:image/png;base64, ${managerDashboardData?.org?.logo}`} height="60px" alt="Organization Logo" />}
                        <Typography variant="h4" component="h1" gutterBottom> {managerDashboardData?.org?.name} </Typography>
                    </Grid>
                    {/* Teachers Table */}
                    <Grid item xs={12} md={12} lg={12}>
                        <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1 }}>
                            <Teachers orgId={org_id} />
                        </Box>
                    </Grid>

                    {/* Tokens Graph */}
                    <Grid item xs={12} md={6} lg={6}>
                        <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1 }}>
                            <DashboardChart
                                orgId={org_id}
                                title='Token Usage'
                                fetchData={fetchTokensCount}
                                data={managerDashboardData?.tokensCount}
                                dataKey='tokens'
                                chartColor='#8884d8'
                                chartType={chartTypes.areaChart}
                            />
                        </Box>
                    </Grid>

                    {/* Students Component */}
                    <Grid item xs={12} md={6} lg={6}>
                        <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1 }}>
                            <DashboardChart
                                orgId={org_id}
                                title='Student Responses'
                                fetchData={fetchResponsesCount}
                                data={managerDashboardData?.responsesCount}
                                dataKey='responses'
                                chartColor='#ff7300'
                                chartType={chartTypes.barChart}
                            />
                        </Box>
                    </Grid>

                    {/* Exams Component */}
                    <Grid item xs={12} md={12} lg={12}>
                        <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1 }}>
                            <DashboardChart
                                orgId={org_id}
                                title='Exams Created'
                                fetchData={fetchExamTaken}
                                data={managerDashboardData?.examsTaken}
                                dataKey='exams'
                                chartColor='#82ca9d'
                                chartType={chartTypes.barChart}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default ManagerDashboard;
