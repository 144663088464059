import { IconButton, Tooltip } from "@mui/material";
import HideImageIcon from '@mui/icons-material/HideImage';
import { useLanguage } from "../../contexts/languageContext";

export function DeleteImageButton({ questionId, handleClickDeleteImage }) {
    const { languageData } = useLanguage();
    return (
        <Tooltip title={languageData?.preview?.delete_image ?? "Delete image"} arrow>
            <IconButton onClick={e => handleClickDeleteImage(e.currentTarget, questionId)}>
                <HideImageIcon />
            </IconButton>
        </Tooltip>
    )
}