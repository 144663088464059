import React from 'react';
import MathIcon from '@mui/icons-material/Functions';
import ScienceIcon from '@mui/icons-material/Science';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import HistoryIcon from '@mui/icons-material/History';
import PublicIcon from '@mui/icons-material/Public';
import PaletteIcon from '@mui/icons-material/Palette';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ComputerIcon from '@mui/icons-material/Computer';
import LanguageIcon from '@mui/icons-material/Language';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import BrushIcon from '@mui/icons-material/Brush';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import TheatersIcon from '@mui/icons-material/Theaters';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import BuildIcon from '@mui/icons-material/Build';
import BusinessIcon from '@mui/icons-material/Business';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const subjects = [
    {
        subject: 'Math',
        icon: <MathIcon />,
        hebrewSubject: 'מתמטיקה'
    },
    {
        subject: 'Science',
        icon: <ScienceIcon />,
        hebrewSubject: 'מדעים'
    },
    {
        subject: 'Literature',
        icon: <MenuBookIcon />,
        hebrewSubject: 'ספרות'
    },
    {
        subject: 'History',
        icon: <HistoryIcon />,
        hebrewSubject: 'היסטוריה'
    },
    {
        subject: 'Geography',
        icon: <PublicIcon />,
        hebrewSubject: 'גאוגרפיה'
    },
    {
        subject: 'Art',
        icon: <PaletteIcon />,
        hebrewSubject: 'אמנות'
    },
    {
        subject: 'Music',
        icon: <MusicNoteIcon />,
        hebrewSubject: 'מוזיקה'
    },
    {
        subject: 'Physical Education',
        icon: <DirectionsRunIcon />,
        hebrewSubject: 'חינוך גופני'
    },
    {
        subject: 'Computer Science',
        icon: <ComputerIcon />,
        hebrewSubject: 'מדעי המחשב'
    },
    {
        subject: 'Language',
        icon: <LanguageIcon />,
        hebrewSubject: 'שפה'
    },
    {
        subject: 'Sports',
        icon: <SportsSoccerIcon />,
        hebrewSubject: 'ספורט'
    },
    {
        subject: 'Design',
        icon: <BrushIcon />,
        hebrewSubject: 'עיצוב'
    },
    {
        subject: 'Literacy',
        icon: <LocalLibraryIcon />,
        hebrewSubject: 'אוריינות'
    },
    {
        subject: 'Performing Arts',
        icon: <TheatersIcon />,
        hebrewSubject: 'אמנויות הבמה'
    },
    {
        subject: 'Health',
        icon: <HealthAndSafetyIcon />,
        hebrewSubject: 'בריאות'
    },
    {
        subject: 'Creativity',
        icon: <EmojiObjectsIcon />,
        hebrewSubject: 'יצירתיות'
    },
    {
        subject: 'Engineering',
        icon: <BuildIcon />,
        hebrewSubject: 'הנדסה'
    },
    {
        subject: 'Business',
        icon: <BusinessIcon />,
        hebrewSubject: 'עסקים'
    },
    {
        subject: 'Other',
        icon: <MoreHorizIcon />,
        hebrewSubject: 'אחר'
    },
];


export default subjects;
