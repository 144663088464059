import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { Assignment, ErrorOutline } from '@mui/icons-material';
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const MistakenQuestions = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <Assignment color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Identify and Correct Mistaken Questions to Improve Test Accuracy and Ensure Fair Assessments">
                                    Identify and Correct Mistaken Questions to Improve Test Accuracy and Ensure Fair Assessments
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Embrace the transformative power of advanced artificial intelligence (AI) technology to identify, analyze, and correct errors in your test and exam questions. Our state-of-the-art AI solutions are meticulously designed to enhance the accuracy, fairness, and overall quality of your assessments. By leveraging sophisticated algorithms and cutting-edge AI-driven tools, our platform enables educators and administrators to detect subtle inaccuracies and ambiguities that might otherwise go unnoticed, ensuring that your evaluations accurately reflect student knowledge, comprehension, and understanding.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Our AI-powered platform provides a comprehensive suite of features to significantly improve the precision of your test questions. It offers detailed insights into common mistakes and errors, enabling you to refine and enhance your assessment materials. By addressing these issues, you can create well-structured, clear, and accurate questions that provide a fair evaluation of student performance. This approach not only helps in maintaining high standards in educational assessments but also contributes to better learning outcomes by ensuring that students are assessed based on correctly formulated questions.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                With our advanced AI technology, you can achieve unparalleled precision in your test evaluations. Our tools provide actionable recommendations based on in-depth analysis of test questions, helping you to identify and correct mistakes effectively. This process ensures that your assessments are both reliable and valid, thereby supporting more accurate and fair evaluations. By refining your test questions and improving their quality, you contribute to a more effective assessment process that enhances educational success and supports student achievement.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Why Address Mistaken Questions in Assessments? Enhancing Test Quality and Fairness
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Assignment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Identify and Fix Mistakes in Test Questions with AI Precision</Typography>
                                    </Box>
                                    Utilize our AI-powered platform to accurately pinpoint and address common errors in your test questions. By correcting these mistakes, you ensure that your assessments are not only fair but also highly effective. This process enhances the reliability and validity of your tests, ensuring that they accurately measure student knowledge and abilities. Improved question quality leads to more equitable evaluations and supports high standards in educational assessments, ultimately benefiting both educators and students.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <ErrorOutline color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Enhance Learning Outcomes and Instructional Quality through Accurate Assessments</Typography>
                                    </Box>
                                    Leverage our advanced AI tools to analyze incorrect responses and refine your instructional strategies. By addressing and correcting common misconceptions and errors identified through AI analysis, you can better tailor your teaching methods to meet the needs of your students. This targeted feedback helps improve student comprehension, retention, and overall learning experiences, leading to more successful educational outcomes. Enhancing the quality of your assessments contributes to a more effective teaching and learning process, supporting both educational objectives and student success.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                How to Utilize AI for Effective Mistaken Question Analysis and Improve Test Quality and Accuracy?
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Apply our cutting-edge AI-driven solutions to thoroughly analyze and correct mistakes in your test and exam questions. Our platform provides a robust set of analysis capabilities that help you improve your assessment strategies by identifying and addressing common errors. By integrating these insights into your test development process, you can significantly enhance the quality and effectiveness of your educational content. Embrace our technology to optimize your assessment procedures, ensuring accurate, fair, and reliable evaluations that support better student performance and educational success. By leveraging AI for mistaken question analysis, you are investing in the future of education and enhancing the overall quality of learning.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default MistakenQuestions;
